import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import myStoriesReducer from '../features/myStory/myStoriesSlice';
import coverReducer from '../features/master/coverSlice';
import categoryReducer from '../features/master/categorySlice';
import uploadCoverSlice from '../features/upload/uploadCoverSlice';
import actionMyStorySlice from '../features/myStory/actionMyStorySlice';
import myStorySlice from '../features/myStory/myStorySlice';
import termsSlice from '../features/terms/termsSlice';
import autosaveSlice from '../features/chapter/autosaveSlice';
import actionChapterSlice from '../features/chapter/actionChapter';
import uploadChapterPdfSlice from '../features/upload/uploadChapterPdf';
import uploadChapterImageSlice  from '../features/upload/uploadChapterImg';
import chapterSlice from '../features/chapter/chapterSlice';
import myProfileSlice from '../features/profile/myProfileSlice';
import dynamicLinkSlice from '../features/other/dynamicLinkSlice';
import myListChapterSlice from '../features/chapter/myListChapterSlice';
import costUnlockChapterSlice from '../features/chapter/costUnlockChapterSlice';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    myStories: myStoriesReducer,
    cover: coverReducer,
    category: categoryReducer,
    uploadCover: uploadCoverSlice,
    actionMyStory: actionMyStorySlice,
    myStory: myStorySlice,
    terms: termsSlice,
    autosave: autosaveSlice,
    actionChapter: actionChapterSlice,
    uploadChapterPdf: uploadChapterPdfSlice,
    uploadChapterImage: uploadChapterImageSlice,
    chapter: chapterSlice,
    myProfile: myProfileSlice,
    dynamicLink:dynamicLinkSlice,
    myListChapter: myListChapterSlice,
    costUnlockChapter: costUnlockChapterSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck:false,
    
  }),
});
