import {
  Navbar,
  Container,
  ButtonGroup,
  Form as FormBootstrap,
  Card,
  Spinner,
  Nav,
} from "react-bootstrap";
import CustomButton from "../../../../utils/components/customButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  autosave,
  resetAutosave,
} from "../../../../features/chapter/autosaveSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { stripHtml } from "string-strip-html";
import {
  postDeleteChapter,
  upsertChapter,
} from "../../../../features/chapter/actionChapter";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import SizedBox from "../../../../utils/components/sizedbox";
import PdfChapter from "./pdfChapter";
import { toast } from "react-toastify";
import ImageChapter from "./imageChapter";
import { resetUploadChapterImage } from "../../../../features/upload/uploadChapterImg";
import { resetUploadChapterPdf } from "../../../../features/upload/uploadChapterPdf";
import IconButton from "../../../../utils/components/iconButton";
import { useBackListener } from "../../../../utils/helpers/useBackListener";
import PopupMenuChapter from "./components/popupMenuChapter";
import { isMobile } from "react-device-detect";
import CircleProfile from "../../../../utils/components/circleProfile";
import ExitConfirmChapterModal from "./components/exitConfirmChapterModal";
import Sizedbox from "../../../../utils/components/sizedbox";
import { resetChapter } from "../../../../features/chapter/chapterSlice";
import { getMyStory } from "../../../../features/myStory/myStorySlice";
import ConfirmSetTopUpModal from "../components/confirmSetTopUpModal";
import SetTopUpModal from "../components/setTopupModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlusMinus } from "@fortawesome/free-solid-svg-icons";

const CreateChapter = () => {
  const dispatch = useDispatch();
  const [isautosaveStart, setAutoSaveStart] = useState(false);
  const autosaveState = useSelector((state) => state.autosave);
  const [isPublish, setPublish] = useState(false);
  const { storyId } = useParams();
  const actionChapter = useSelector((state) => state.actionChapter);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("text");
  const [mediaPdf, setMediaPdf] = useState([]);
  const [mediaImage, setMediaImage] = useState([]);
  const [navigateUrl, setNavigate] = useState("");
  const [isBlocking, setBlocking] = useState(false);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const title = useRef("");
  const content = useRef("");
  const chapterId = useRef("");
  const wordsCount = useRef(0);
  const autosaveTime = useRef(null);
  const [confirmTopupModal, setConfirmTopupModal] = useState(false);
  const [TopupModal, setTopupModal] = useState(false);
  const actionChapterState = useSelector((state) => state.actionChapter);
  const resetMedia = () => {
    dispatch(resetUploadChapterImage());
    dispatch(resetUploadChapterPdf());
    dispatch(resetAutosave());
    dispatch(resetChapter());
    setMediaPdf([]);
    setMediaImage([]);
    clearTimeout(autosaveTime.current);
  };

  useBackListener(({ location }) => {
    resetMedia();
    navigate(`/edit-story/${storyId}`, { replace: true });
  });

  useEffect(() => {
    dispatch(getMyStory(storyId));
    resetMedia();
  }, [dispatch]);

  useEffect(() => {
    chapterId.current = autosaveState?.data?.chapter_id ?? "";
  }, [autosaveState.data]);

  const onExitPage = () => {
    if (autosaveState?.data?.chapter_id) {
      setLoadingDelete(true);
      dispatch(
        postDeleteChapter({
          chapterId: autosaveState?.data?.chapter_id ?? "",
          storyId: storyId,
        })
      )
        .then(unwrapResult)
        .then((_) => {
          setLoadingDelete(false);
          setNavigate(`/edit-story/${storyId}`);
        })
        .catch((_) => {
          setLoadingDelete(false);
        });
    } else {
      setNavigate(`/edit-story/${storyId}`);
    }
  };

  const autoSave = () => {
    autosaveTime.current = setTimeout(() => {
      if (content.current !== "") {
        dispatch(
          autosave({
            title: title.current,
            storyId: storyId,
            status: "draft",
            saveDraft: "true",
            fullText: content.current,
            type: "text",
            media: [],
            chapterId: chapterId.current,
          })
        );
      }
      autoSave();
    }, 30000);
  };

  const onSubmitCreate = (_isPublish) => {
    let type = "";
    let fullText = "";

    if (title.current.trim() === "") {
      toast.error("Judul cerita wajib diisi", { theme: "colored" });
      return;
    } else if (title.current.trim().length > 100) {
      toast.error("Judul cerita maksimal 100 karakter", { theme: "colored" });
      return;
    } else if (selectedTab === "text") {
      if (stripHtml(content.current.trim()).result.length === 0) {
        toast.error("Isi cerita wajib diisi", { theme: "colored" });
        return;
      } else if (stripHtml(content.current.trim()).result.length < 50) {
        toast.error("Tulisan minimal 50 karakter", { theme: "colored" });
        return;
      }
      type = "text";
      fullText = content.current;
    } else if (selectedTab === "pdf") {
      if (mediaPdf.length === 0) {
        toast.error("PDF wajib diisi!", { theme: "colored" });
        return;
      }
      type = "pdf";
    } else if (selectedTab === "image") {
      if (mediaImage.length === 0) {
        toast.error("Gambar wajib diisi!", { theme: "colored" });
        return;
      }
      // if(mediaImage.length > 20){
      //   toast.error('Maksimal gambar 20',{theme: 'colored'});
      //   return;
      // }
      type = "image";
    }
    dispatch(
      upsertChapter({
        title: title.current.trim(),
        storyId: storyId,
        status: _isPublish ? "active" : "draft",
        saveDraft: _isPublish ? "false" : "true",
        fullText: fullText.trim(),
        type: type,
        media:
          selectedTab === "pdf"
            ? mediaPdf
            : selectedTab === "image"
            ? mediaImage
            : [],
        wordsCount: wordsCount.current,
        chapterId: autosaveState?.data?.chapter_id ?? "",
      })
    )
      .then(unwrapResult)
      .then((res) => {
        // dispatch(postRemoveRedis({storyId: storyId}))
        //   .then(unwrapResult)
        //   .then((_) => {
        //     resetMedia();
        //     setNavigate(`/edit-story/${storyId}`,{'replace': true});
        // });
        // resetMedia();
        // setNavigate(`/edit-story/${storyId}`,{'replace': true});
        if (
          res.data?.chapter?.user?.company_type &&
          !res.data?.chapter?.user?.is_set_cost
        ) {
          setConfirmTopupModal(true);
        } else {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }
      });
  };

  useEffect(() => {
    if (navigateUrl !== "") {
      setBlocking(false);
      resetMedia();
      navigate(navigateUrl, { replace: true });
    }
  }, [navigateUrl, isBlocking]);

  return (
    <>
      <ExitConfirmChapterModal
        isBlocking={isBlocking && selectedTab === "text"}
        onExit={onExitPage}
        isLoadingExit={isLoadingDelete}
        onSave={() => {
          setPublish(false);
          onSubmitCreate(false);
        }}
        isLoading={actionChapter.isLoading && !isLoadingDelete}
      />

      <ConfirmSetTopUpModal
        isOpen={confirmTopupModal}
        setOpen={setConfirmTopupModal}
        onConfirm={() => {
          setConfirmTopupModal(false);
          setTopupModal(true);
        }}
        onCancel={() => {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }}
      />
      <SetTopUpModal
        isOpen={TopupModal}
        setOpen={setTopupModal}
        chapterId={actionChapterState?.data?.chapter_id}
        onCancel={() => {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }}
        onSuccess={() => {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }}
      />

      <Navbar
        bg="white"
        style={{ borderBottom: "2px solid #e6e8eb" }}
        sticky="top"
      >
        <Container>
          <ButtonGroup>
            <IconButton
              faIcon={faArrowLeft}
              onClick={() =>
                navigate(`/edit-story/${storyId}`, { replace: true })
              }
            />
            <Sizedbox width="10px" />
            <Navbar.Text>
              <p className="title-appbar">Buat Bab</p>
            </Navbar.Text>
          </ButtonGroup>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!isMobile && (
              <>
                <CustomButton
                  size="sm"
                  onClick={() => {
                    setPublish(false);
                    onSubmitCreate(false);
                  }}
                  isLoading={actionChapter.isLoading && !isPublish}
                  variant="secondary"
                >
                  Draf
                </CustomButton>
                <div style={{ width: "10px" }}></div>
                <CustomButton
                  isLoading={actionChapter.isLoading && isPublish}
                  size="sm"
                  onClick={() => {
                    setPublish(true);
                    onSubmitCreate(true);
                  }}
                >
                  Terbitkan
                </CustomButton>
              </>
            )}
            <CircleProfile />
          </div>
        </Container>
      </Navbar>

      {isMobile && (
        <Navbar fixed="bottom" bg="white">
          <Container fluid>
            <CustomButton
              className="w-100"
              onClick={() => {
                setPublish(false);
                onSubmitCreate(false);
              }}
              isLoading={actionChapter.isLoading && !isPublish}
              variant="secondary"
            >
              Draf
            </CustomButton>
            <div style={{ width: "10px" }}></div>
            <CustomButton
              className="w-100"
              isLoading={actionChapter.isLoading && isPublish}
              onClick={() => {
                setPublish(true);
                onSubmitCreate(true);
              }}
            >
              Terbitkan
            </CustomButton>
          </Container>
        </Navbar>
      )}

      <Container>
        <Card className={`my-4 ${isMobile ? "p-2" : "p-4"}`}>
          <Card.Body>
            <PopupMenuChapter
              fullText={content.current}
              title={title.current}
              type={selectedTab}
            />
            <FormBootstrap.Group className="mb-3">
              <FormBootstrap.Label>Judul Bab</FormBootstrap.Label>
              <FormBootstrap.Control
                placeholder="Tulis judul bab"
                maxLength="100"
                onChange={(e) => {
                  title.current = e.target.value;
                  const counterTitle = document.querySelector(".counter-title");
                  counterTitle.textContent = `${title.current.length} / 100`;
                  if (e.target.value !== "") {
                    setBlocking(true);
                  } else {
                    setBlocking(false);
                  }
                }}
              />
              <FormBootstrap.Text
                className="text-muted counter-title"
                style={{ display: "inline-block", float: "right" }}
              >
                0 / 100
              </FormBootstrap.Text>
            </FormBootstrap.Group>
            <Nav
              className="my-4"
              style={{ display: "flex", justifyContent: "center" }}
              onSelect={(selectedKey) => {
                setSelectedTab(selectedKey);
              }}
              defaultActiveKey={selectedTab}
            >
              <Nav.Item>
                <Nav.Link eventKey="text">Teks</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pdf">PDF</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="image">Gambar</Nav.Link>
              </Nav.Item>
            </Nav>
            {selectedTab === "text" && (
              <>
                {autosaveState.isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <img
                    alt="autosave"
                    src="/assets/AUTOSAVE.png"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                    width="20px"
                    height="20px"
                  />
                )}

                <FormBootstrap.Text
                  style={{ display: "inline-block", margin: "auto 10px" }}
                >
                  Autosave
                </FormBootstrap.Text>
                <SizedBox height="20px" />
                <CKEditor
                  editor={Editor}
                  data={content.current}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "min-height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                    editor.plugins
                      .get("WordCount")
                      .on("update", (evt, stats) => {
                        const wordCount = document.querySelector(".word-count");
                        wordCount.textContent = stats.words;
                        wordsCount.current = stats.words;
                      });
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    //setContent(data);
                    content.current = data;

                    if (stripHtml(data).result !== "" && !isBlocking) {
                      setBlocking(true);
                    } else if (stripHtml(data).result === "" && isBlocking) {
                      setBlocking(false);
                    }
                    let dataLength = stripHtml(data).result.length;

                    if (!isautosaveStart && dataLength >= 50) {
                      setAutoSaveStart(true);
                      autoSave();
                    }
                  }}
                  onBlur={(event, editor) => {
                    //props.setTouched('content',false);
                  }}
                  onFocus={(event, editor) => {}}
                />

                <FormBootstrap.Text>
                  Kata: &nbsp; <FontAwesomeIcon icon={faPlusMinus} /> &nbsp;
                </FormBootstrap.Text>
                <FormBootstrap.Text className="word-count">
                  0
                </FormBootstrap.Text>
              </>
            )}
            {selectedTab === "pdf" && (
              <PdfChapter
                setMedia={(val) => {
                  setMediaPdf(val);
                  if (val.length > 0) {
                    setBlocking(true);
                  } else {
                    setBlocking(false);
                  }
                }}
              />
            )}
            {selectedTab === "image" && (
              <ImageChapter
                images={[]}
                setMedia={(val) => {
                  setMediaImage(val);
                  if (val.length > 0) {
                    setBlocking(true);
                  } else {
                    setBlocking(false);
                  }
                }}
              />
            )}
          </Card.Body>
        </Card>
        {isMobile && <SizedBox height="70px" />}
      </Container>
    </>
  );
};

export default CreateChapter;
