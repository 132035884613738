import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import masterService from "../../services/masterService";

const name = 'cover';

export const getCover = createAsyncThunk(
    `${name}/getCover`, async () => {
        return await masterService.getCover();
    });

const coverSlice = createSlice({
    name: name,
    initialState:{
        status: false,
        isLoading: false,
        message:'',
        data: [],
    },
    extraReducers: {
        [getCover.pending](state){
            state.status = false;
            state.isLoading = true;
        },
        [getCover.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data = payload.data.cover;
        },
        [getCover.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            state.message = error.message;
        },
        
    }
})

export default coverSlice.reducer;