import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import masterService from "../../services/masterService";

const name = 'category';

export const getCategory = createAsyncThunk(
    `${name}/getCategory`,async () => {
        return await masterService.getCategory();
    });

const categorySlice = createSlice({
    name: name,
    initialState: {
        status: false,
        message: '',
        isLoading: false,
        data: [],
    },
    extraReducers:{
        [getCategory.pending](state){
            state.status = false;
            state.isLoading = true;
        },
        [getCategory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data = payload.data.category;
        },
        [getCategory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            state.message = error.message;
        },
    }
});

export default categorySlice.reducer;