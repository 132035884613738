import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ItemImageChapter = ({src,index,onDelete}) => {
  return (
    <div key={index} className="containerImage" >

        <img alt='imagechapter' src={src} className="image"/>
        <div className="close-icon" onClick={() => onDelete(index)}><FontAwesomeIcon icon={faXmark} /></div>
    </div>
  )
}

export default ItemImageChapter