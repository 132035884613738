import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router'
import NavbarReading from '../../utils/components/navbarReading';

const ReadingPage = () => {
  const {storyId} = useParams();
  const {chapterId} = useParams();
  useEffect(() => {
  },[chapterId]);

  return (
    <>
    <Card className="my-2">
      StoryId: {storyId}
      chapterId: {chapterId}
    </Card>
    <NavbarReading/>
    </>
  )
}

export default ReadingPage