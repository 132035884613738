import {Button,Spinner} from 'react-bootstrap';

const CustomButton = ({variant = 'primary',isLoading = false,disabled = false,size = 'md',children,onClick,styles,...props}) => {
    const primary = {
        fontSize: size==='sm' ? '14px' : '16px',
        fontWeight: 600,
        backgroundColor: '#20BF6B',
        color: 'white',    
        borderRadius: size==='sm' ? '6px' : '9px',
        border: 'none',
        boxShadow: 'none',
        
        padding: size==='sm' ? '6px 15px' : '10px 30px',
        ...styles,
    }

    const secondary = {
        fontSize: size==='sm' ? '14px' : '16px',
        fontWeight: 600,
        color: '#20BF6B',
        backgroundColor: '#FFFFFF',
        borderRadius: size==='sm' ? '6px' : '9px',
        border: '2px solid #20BF6B',
        boxShadow: 'none',
        padding: size==='sm' ? '4px 15px' : '10px 30px',
        ...styles,
    }
  return (
    <Button 
        style={variant==='primary' ? primary : secondary} 
        onClick={onClick}
        disabled={isLoading || disabled}
        {...props}
        >
            {isLoading && <Spinner animation="border" size="sm">
                &nbsp;
            </Spinner>}
             {children}
    </Button>
  )
}

export default CustomButton