import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import './style.css';
import IconButton from './iconButton';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const CustomTextField = ({label,disabled = false,maxLength, type,isCountCharacter, ...props}) => {
  const [isShowPassword,setShowPassword] = useState(false);
  const [field,meta] = useField(props);
  return (
    <Form.Group className="position-relative mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control 
          disabled={disabled}
          maxLength={maxLength}
          style={{
            position:'relative',
            paddingRight: '40px',
            borderColor: type==='password' && meta.touched && meta.error && 'red'
          }} 
          type={type !== 'password' ? type : isShowPassword ? 'text' : 'password'} 
          {...field} 
          {...props} 
          isInvalid={meta.touched && meta.error && type !== 'password'}
        />

        {type==='password' && (
          <IconButton 
          faIcon={isShowPassword ? faEye : faEyeSlash}
          onClick={() => setShowPassword(!isShowPassword)}
          style={{
            position: 'absolute',
            right:0,
            top:'50px',
            color:'#666E7D',
            fontSize:'16px',
          }} />
        )}

        {isCountCharacter && (
          <Form.Text className="text-muted" style={{display:'inline-block',float:'right'}}>
            {field.value.length} / {maxLength}
          </Form.Text>
        )}

        {meta.touched && meta.error && (
           <Form.Text className="text-danger">
            {meta.error}
          </Form.Text>
        )}
       
    </Form.Group>
  )
}

export default CustomTextField