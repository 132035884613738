import React from 'react'
import {Row} from 'react-bootstrap';
import MyStoryEmpty from '../components/myStoryEmpty';
import MyStoryError from '../components/myStoryError';
import MyStoryShimmer from '../components/myStoryShimmer';
import ItemStoryRelease from './itemStoryRelease';
const MyStoryRelease = ({myStories,onError}) => {
  return (
    <>
      {myStories.isLoading && <MyStoryShimmer key={0} length={10}/> }
      {!myStories.isLoading && myStories.status && myStories.data.length===0 && <MyStoryEmpty />}
      {!myStories.isLoading && myStories.status && (
        <Row>
          {myStories.data.map((story,i) => (
            <ItemStoryRelease key={i} data={story} i={i}/>
          ))}
        </Row>
      )}
      {!myStories.isLoading && !myStories.status && <MyStoryError onError={onError}/> }
    </>
  )
}

export default MyStoryRelease