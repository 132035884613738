import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import otherService from '../../services/otherService';
const name = 'link';

export const postDynamicLink = createAsyncThunk(
    `${name}/postDynamic`, async () => {
        return await otherService.postDynamicLink();
    }
)

const dynamicLinkSlice = createSlice({
    name: name,
    initialState:{
        status: false,
        isLoading: false,
        data: null,
    },
    extraReducers: {
        [postDynamicLink.pending](state){
            state.status = false;
            state.isLoading = true;
        },
        [postDynamicLink.fulfilled](state,{payload}){
            state.status = true;
            state.isLoading = false;
            state.data = payload.shortLink;
        },
        [postDynamicLink.rejected](state){
            state.status = false;
            state.isLoading = false;
        },
    },
})

export default dynamicLinkSlice.reducer;