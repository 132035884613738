import {useDropzone} from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import './style.css';
import Sizedbox from '../../../../utils/components/sizedbox';
import { useDispatch, useSelector } from 'react-redux';
import {setDataUploadChapterPdf, uploadChapterPdf,setPdfOriginName} from '../../../../features/upload/uploadChapterPdf';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomButton from '../../../../utils/components/customButton';
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    position:'relative',
    color: '#bdbdbd',
    innerHeight: '200px',
    outerHeight:'500px',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


const PdfChapter = ({setMedia,pdf = []}) => {
  const dispatch = useDispatch();
  const [namePdfEdit,setNamePdfEdit] = useState(''); 
  const uploadPdfState = useSelector(state => state.uploadChapterPdf);
    const {acceptedFiles,  
        getRootProps,
        open,
        fileRejections,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject} = useDropzone({
        multiple:false,
        accept:'.pdf',
        noClick:true,
        noKeyboard: true,
        // maxSize: 100 * (1024 * 1024),
    });
  
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);

      useEffect(() => {
        if(pdf.length > 0){
          const params = pdf.map(pd => ({
            'status': true,
            'message': "Berhasil mengunggah berkas",
            'data': {
              '_id': pd._id,
              'url': pd.url,
              'owner':'',
            }
          }));
          dispatch(setDataUploadChapterPdf(params));
          let name = pdf[0].url.split('/').pop();
          setNamePdfEdit(name);
        }
      },[pdf,dispatch]);

    useEffect(() => {
      if(uploadPdfState.data != null){
        setMedia(uploadPdfState.data.map(pdf => (pdf.data._id)));
      }
    },[uploadPdfState]);

    useEffect(() => {
      if(acceptedFiles.length > 0){
        dispatch(setPdfOriginName(acceptedFiles[0].name));
        dispatch(uploadChapterPdf(acceptedFiles[0]));
      }
    },[acceptedFiles,dispatch]);

    useEffect(() => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(err => {
          switch(err.code){
            case 'file-invalid-type':
              toast.error('File harus bertipe .PDF',{theme:'colored'});
              break;  
            // case 'file-too-large':
            //   toast.error('PDF maksimal 100 mb',{theme:'colored'});
            //   break;  
            default: 
              toast.error(err.message);
          }
          
        } );
      })
    },[fileRejections]);

    
    return (
      <section className="container">
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <Sizedbox height="100px" />
          {uploadPdfState.isLoading 
            ? <Spinner animation="border" />
            : uploadPdfState.data != null 
              ? <p 
                  style={{
                    textAlign:'center',
                    color:'#929AA7',
                    display: 'inline-block',
                    width:'100%',
                    
                  }}>{ acceptedFiles.length > 0 ? acceptedFiles[0].name : uploadPdfState.originName ?? namePdfEdit}</p> 
              : <p style={{textAlign:'center'}}>Drag 'n' drop file PDF disini atau klik tombol dibawah untuk memilih file PDF</p>
            
          }
            <CustomButton onClick={open}>Pilih PDF</CustomButton>
           <Sizedbox height="100px" />
        </div>
        
      </section>
    );
}

export default PdfChapter