import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/auth/login/loginPage";
import ErrorPage from "./pages/customPage/errorPage";
import ForgotPasswordPage from "./pages/auth/forgotPassword/forgotPasswordPage";
import ReadingPage from "./pages/reading/readingPage";
import MyStoryPage from "./pages/myStory/myStoryPage";
import CreateMyStory from "./pages/myStory/form/createMyStory";
import ProtectedRoute from "./utils/components/protectedRoute";
import EditMyStory from "./pages/myStory/form/editMyStory";
import CreateChapter from "./pages/myStory/form/chapter/createChapter";
import EditChapter from "./pages/myStory/form/chapter/editChapter";
import TermsWriting from "./pages/terms/termsWriting";
import MaintenanceRoute from "./utils/components/maintenanceRoute";
import LogoutPage from "./pages/customPage/logoutPage";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
function App() {
  return (
    <>
      <Router>
        <TermsWriting />
        <Routes>
          <Route element={<MaintenanceRoute />}>
            {/*=================== Auth==================  */}
            {/* <Route path="/" element={<HomePage/>} /> */}
            <Route
              path="login"
              element={
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                >
                  <LoginPage />
                </GoogleReCaptchaProvider>
              }
            />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="logout" element={<LogoutPage />} />

            {/*=================== Chapter==================  */}
            <Route
              path="book/read/:storyId/:chapterId"
              element={<ReadingPage />}
            />

            {/*=================== myStory==================  */}
            <Route element={<ProtectedRoute />}>
              <Route index element={<MyStoryPage />} />
              <Route path="create-story" element={<CreateMyStory />} />
              <Route path="edit-story/:storyId" element={<EditMyStory />} />
              <Route
                path="create-chapter/:storyId"
                element={<CreateChapter />}
              />
              <Route
                path="edit-chapter/:storyId/:chapterId"
                element={<EditChapter />}
              />
            </Route>

            {/*=================== Error==================  */}
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
