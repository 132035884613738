import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {getCover} from '../../../features/master/coverSlice';
import { Modal,Row,Col } from 'react-bootstrap';
import CoverBook from '../../../utils/components/coverBook';
const KbmCoverModal = ({changeCoverKbm, ...props}) => {
    const dispatch = useDispatch();
    const covers = useSelector((state) => state.cover);
    useEffect(() => {
        dispatch(getCover());
    },[dispatch]);

  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
       Kbm Sampul
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row >
          {covers.isLoading && <p>Loading</p>}
          {covers.status && covers.data.isEmpty && <p>Kosong</p>}
          {!covers.status && <p>Error</p>}
          {covers.status && covers?.data?.map((cover, i) => <Col key={i}> 
              <CoverBook src={cover.image.url} width="84px" height="120px" margin="10px 0" onClick={()=> changeCoverKbm(cover.image._id,cover.image.url)}/>
            </Col>
          )}
      </Row>
    </Modal.Body>
  </Modal>
  )
}

export default KbmCoverModal