import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

const IconButton = ({ref,onClick,faIcon,style}) => {
  return (
    <Button 
    ref={ref} 
    onClick={onClick}
    style={{
       
        backgroundColor: 'transparent',
        border: 'none',
        color: 'black',
        fontSize: '20px',
        boxShadow: 'none',
        ...style,
        }}
        ><FontAwesomeIcon icon={faIcon} />
    </Button>
  )
}

export default IconButton