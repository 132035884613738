import {Button,Card,Overlay} from 'react-bootstrap';
import { useState,useRef, useEffect } from 'react';
import ItemMenuEditMyStory from './itemMenuEditMyStory';
import { useDispatch } from 'react-redux';
import { completeStory, deleteStory, monetizeStory, unCompleteStory } from '../../../features/myStory/actionMyStorySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ModalConfirm from '../../../utils/components/modalConfirm';
import ModalAlert from '../../../utils/components/modalAlert';
import TermsMonetize from '../../terms/termsMonetize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
const PopupMenuEditMyStory = () => {
  const myStory = useSelector((state) => state.myStory);
  const myListChapterState = useSelector(state => state.myListChapter);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const target = useRef(null);
  const [monetizeShow,setMonetizeShow] = useState(false);
  const [isProgress, setProgress] = useState(myStory.data.in_progress);
  const [isMonetize, setMonetize] = useState(myStory.data.monetize);
  const [isDialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [isDialogMonetizeShow,setDialogMonetizeShow] = useState(false);
  const actionMyStoryState = useSelector(state => state.actionMyStory);
  useEffect(() => {
    setProgress(myStory.data.in_progress);
    setMonetize(myStory.data.monetize);
  },[myStory]);

  const onMonetizeShow = () => {
    setMonetizeShow(true);
    setShow(false);
  }

  const onMonetize = () => {
    dispatch(monetizeStory(myStory.data._id))
    .then(unwrapResult)
    .then(() => {
      setMonetize(true);
      setMonetizeShow(false);
    } );
  }

  const onCompleteStory = () => {
    setShow(false);
    dispatch(completeStory(myStory.data._id))
    .then(unwrapResult)
    .then(() => {
      setProgress(false);
    });
  }

  const onUnCompleteStory = () => {
    setShow(false);
    dispatch(unCompleteStory(myStory.data._id))
    .then(unwrapResult)
    .then(() => {
      setProgress(true);
    });
  }

  const onDeleteStory = () => {
    setShow(false);
    dispatch(deleteStory(myStory.data._id))
    .then(unwrapResult)
    .then(() => {
      setDialogDeleteOpen(false);
      navigate('/');
    });
  }

  const onKbmPrint = () => {
    setShow(false);
    window.open('https://kbmprint.com/','_blank');
  }

  return (
    <>
    <TermsMonetize show={monetizeShow} onAgree={onMonetize} onHide={() => setMonetizeShow(false)}/>
    <ModalConfirm 
      title="Hapus Cerita"
      description="Apakah anda yakin ingin menghapus ceritamu?"
      show={isDialogDeleteOpen} 
      onYesClick={onDeleteStory} 
      isLoading={actionMyStoryState.isLoading}
      onHide={() => setDialogDeleteOpen(false)} />
     <ModalAlert 
        show={isDialogMonetizeShow} 
        onHide={() => setDialogMonetizeShow(false)}
        description="Cerita ini telah dimonetisasi, untuk dapat melakukan fungsi ini silahkan hubungi admin"
         />
    <Button 
    ref={target} 
    onClick={() => setShow(!show)}
    style={{
        position:'absolute',
        right:'10px',
        top:'10px',
        backgroundColor: 'transparent',
        border: 'none',
        color: 'black',
        fontSize: '20px',
        boxShadow: 'none',
        }}
        > <FontAwesomeIcon icon={faEllipsisV} />
    </Button >
    <Overlay  target={target.current} show={show} placement="bottom">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <Card
            {...props}
            style={{
              position: 'absolute',
              ...props.style,
            }}
          >
            <Card.Body>
              {myListChapterState.data.filter(chapter => chapter.status==='active').length > 7 && (
                <>
                  <ItemMenuEditMyStory 
                  src="/assets/MONETIZE_STORY.png" 
                  onClick={onMonetizeShow}
                  title="Monetisasi Ceritamu"
                  disabled={isMonetize}
                   />
                 <hr></hr>
                </>
              )}
                
                {!isProgress &&  <ItemMenuEditMyStory onClick={onUnCompleteStory} textColor="orange" src="/assets/ON_GOING.png" title="Tandai Belum Selesai" />}
                {isProgress &&  <ItemMenuEditMyStory onClick={onCompleteStory} src="/assets/TANDAI_SELESAI.png" title="Tandai Selesai" />}
                <hr></hr>
                <ItemMenuEditMyStory onClick={onKbmPrint} src="/assets/CETAK_BUKU.png" title="Cetak Buku" />
                <hr></hr>
                <ItemMenuEditMyStory 
                  onClick={() => {
                    setShow(false);
                    if(isMonetize){
                      setDialogMonetizeShow(true);
                    }else{
                      setDialogDeleteOpen(true)
                    }
                  }}
                  textColor="red" src="/assets/SAMPAH.png" 
                  title="Hapus Ceritamu" />
            </Card.Body>
          </Card>
        )}
      </Overlay>
    </>
  )
}

export default PopupMenuEditMyStory;