import React from 'react'
import CustomButton from './customButton';
import {Modal} from 'react-bootstrap';

const ModalAlert = ({description,...props}) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Peringatan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton variant="primary" className="w-100" onClick={props.onHide}>Tutup</CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAlert