import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import chapterService from "../../services/chapterService";

const name = 'chapter';
export const getEditChapter = createAsyncThunk(
    `${name}/chapter/getListChapter`,async ({storyId,chapterId}) =>{
    return await chapterService.editChapter({
        storyId: storyId,
        chapterId: chapterId,
    })
});
let toastId;
const chapterSlice = createSlice({
    name: name,
    initialState: {
        isLoading :false,
        status : false,
        data: null,
    },
    reducers: {
        resetChapter: (state,{payload}) => {
            state.isLoading = false;
            state.status = false;
            state.data = null;
        }
    },
    extraReducers: {
        [getEditChapter.pending](state){
            state.isLoading = true;
            state.status = false;
            toastId = toast.loading('Memuat data');
        },
        [getEditChapter.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = false;
            state.data = payload.data.chapter;
            toast.dismiss();
        },
        [getEditChapter.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        },
    }
});

export const {resetChapter} = chapterSlice.actions;

export default chapterSlice.reducer;