import {
  Navbar,
  Container,
  ButtonGroup,
  Card,
  Spinner,
  Nav,
  Form as FormBootstrap,
} from "react-bootstrap";
import CustomButton from "../../../../utils/components/customButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  autosave,
  resetAutosave,
} from "../../../../features/chapter/autosaveSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { stripHtml } from "string-strip-html";
import { upsertChapter } from "../../../../features/chapter/actionChapter";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useSearchParams } from "react-router-dom";
import SizedBox from "../../../../utils/components/sizedbox";
import PdfChapter from "./pdfChapter";
import { toast } from "react-toastify";
import ImageChapter from "./imageChapter";
import { resetUploadChapterImage } from "../../../../features/upload/uploadChapterImg";
import { resetUploadChapterPdf } from "../../../../features/upload/uploadChapterPdf";
import {
  getEditChapter,
  resetChapter,
} from "../../../../features/chapter/chapterSlice";
import IconButton from "../../../../utils/components/iconButton";
import PopupMenuChapter from "./components/popupMenuChapter";
import { getMyStory } from "../../../../features/myStory/myStorySlice";
import ModalAlert from "../../../../utils/components/modalAlert";
import { useBackListener } from "../../../../utils/helpers/useBackListener";
import { isMobile } from "react-device-detect";
import CircleProfile from "../../../../utils/components/circleProfile";
import ExitConfirmChapterModal from "./components/exitConfirmChapterModal";
import Sizedbox from "../../../../utils/components/sizedbox";
import ConfirmSetTopUpModal from "../components/confirmSetTopUpModal";
import SetTopUpModal from "../components/setTopupModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlusMinus } from "@fortawesome/free-solid-svg-icons";

const EditChapter = () => {
  const [queryParams] = useSearchParams();
  const [isautosaveStart, setAutoSaveStart] = useState(false);
  const autosaveState = useSelector((state) => state.autosave);
  const [isPublish, setPublish] = useState(false);
  const dispatch = useDispatch();
  const { storyId } = useParams();
  const { chapterId } = useParams();
  const actionChapter = useSelector((state) => state.actionChapter);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(
    queryParams.get("type") ?? "text"
  );
  const [mediaPdf, setMediaPdf] = useState([]);
  const [mediaImage, setMediaImage] = useState([]);
  const [navigateUrl, setNavigate] = useState("");
  const chapterState = useSelector((state) => state.chapter);
  const myStory = useSelector((state) => state.myStory);
  const [isDialogMonetizeShow, setDialogMonetizeShow] = useState(false);
  const [isBlocking, setBlocking] = useState(false);
  const [isSetEditData, setEditData] = useState(true);
  const title = useRef("");
  const content = useRef("");
  const status = useRef("");
  const wordsCount = useRef(0);
  const [isAutosave, setAutosave] = useState(false);
  const autosaveTime = useRef();
  const [isFirstInitChapter, setFirstInitChapter] = useState(true);
  const [confirmTopupModal, setConfirmTopupModal] = useState(false);
  const [TopupModal, setTopupModal] = useState(false);
  const actionChapterState = useSelector((state) => state.actionChapter);
  useEffect(() => {
    const type = queryParams.get("type") ?? "text";
    if (type !== "text") {
      dispatch(getMyStory(storyId));
      dispatch(
        getEditChapter({
          storyId: storyId,
          chapterId: chapterId,
        })
      );
    }
    resetMedia();
  }, [chapterId, dispatch, queryParams]);

  useBackListener(({ location }) => {
    resetMedia();
    navigate(`/edit-story/${storyId}`, { replace: true });
  });

  const resetMedia = () => {
    dispatch(resetUploadChapterImage());
    dispatch(resetUploadChapterPdf());
    dispatch(resetAutosave());
    dispatch(resetChapter());
    setMediaPdf([]);
    setMediaImage([]);
    title.current.value = "";
    content.current = "";
    clearTimeout(autosaveTime.current);
    autosaveTime.current = null;
  };

  const autoSave = () => {
    autosaveTime.current = setTimeout(() => {
      if (content.current !== "") {
        dispatch(
          autosave({
            title: title?.current?.value,
            storyId: storyId,
            status: status.current,
            saveDraft: "true",
            fullText: content.current,
            type: "text",
            media: [],
            chapterId: chapterId,
          })
        );
      }
      autoSave();
      setAutosave(true);
    }, 30000);
  };

  useEffect(() => {
    if (navigateUrl !== "") {
      setBlocking(false);
      resetMedia();
      navigate(navigateUrl, { replace: true });
    }
  }, [navigateUrl, isBlocking, navigate]);

  const onSubmitCreate = (
    _isPublish,
    isSaveAsDraft = false,
    isExitDraft = false
  ) => {
    let type = "";
    let fullText = "";
    if (title.current.value.trim() === "" && !isExitDraft) {
      toast.error("Judul cerita wajib diisi", { theme: "colored" });
      return;
    } else if (title.current.value.trim().length > 100 && !isExitDraft) {
      toast.error("Judul cerita maksimal 100 karakter", { theme: "colored" });
      return;
    } else if (selectedTab === "text") {
      if (
        stripHtml(content.current.trim()).result.length === 0 &&
        !isExitDraft
      ) {
        toast.error("Isi cerita wajib diisi", { theme: "colored" });
        return;
      }
      if (
        stripHtml(content.current.trim()).result.length < 50 &&
        !isExitDraft
      ) {
        toast.error("Tulisan minimal 50 karakter!", { theme: "colored" });
        return;
      }
      type = "text";
      fullText = content.current;
    } else if (selectedTab === "pdf") {
      if (mediaPdf.length === 0) {
        toast.error("PDF wajib diisi!", { theme: "colored" });
        return;
      }
      type = "pdf";
    } else if (selectedTab === "image") {
      if (mediaImage.length === 0) {
        toast.error("Gambar wajib diisi!", { theme: "colored" });
        return;
      }
      // if(mediaImage.length > 20){
      //   toast.error('Maksimal gambar 20',{theme: 'colored'});
      //   return;
      // }
      type = "image";
    }
    dispatch(
      upsertChapter({
        title: isExitDraft
          ? chapterState?.data?.title.trim()
          : title.current.value.trim(),
        storyId: storyId,
        status: _isPublish
          ? "active"
          : isSaveAsDraft
          ? chapterState?.data?.status
          : "draft",
        saveDraft: _isPublish ? "false" : "true",
        fullText: isExitDraft
          ? chapterState?.data?.full_text.trim()
          : fullText.trim(),
        type: type,
        wordsCount: wordsCount.current,
        media:
          selectedTab === "pdf"
            ? mediaPdf
            : selectedTab === "image"
            ? mediaImage
            : [],
        chapterId: chapterId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        // dispatch(postRemoveRedis({storyId: storyId}))
        //   .then(unwrapResult)
        //   .then((_) => {
        //     resetMedia();
        //     setNavigate(`/edit-story/${storyId}`);
        // });
        // resetMedia();
        // setNavigate(`/edit-story/${storyId}`,{'replace': true});
        if (
          res.data?.chapter?.user?.company_type &&
          !res.data?.chapter?.is_set_cost
        ) {
          setConfirmTopupModal(true);
        } else {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }
      });
  };

  useEffect(() => {
    if (chapterState.data != null) {
      if (
        queryParams.get("status") === "draft" ||
        chapterState?.data?.status === "draft"
      ) {
        title.current.value =
          chapterState?.data?.title_draft !== ""
            ? chapterState?.data?.title_draft
            : chapterState?.data?.title;
        content.current = chapterState?.data?.full_text_draft ?? "";
      } else {
        title.current.value = chapterState?.data?.title ?? "";
        content.current = chapterState?.data?.full_text ?? "";
      }
      status.current = chapterState?.data?.status;
      const counterTitle = document.querySelector(".counter-title");
      counterTitle.textContent = `${title.current.value.length} / 100`;
      let dataLength = stripHtml(content.current).result.length;
      if (!isautosaveStart && dataLength >= 50) {
        setAutoSaveStart(true);
      }
      setBlocking(true);
      setEditData(false);
    }
  }, [chapterState, isautosaveStart, queryParams]);

  return (
    <>
      <ExitConfirmChapterModal
        isBlocking={isBlocking && selectedTab === "text"}
        onSave={() => {
          setPublish(false);
          onSubmitCreate(false, true);
        }}
        onExit={() => {
          setPublish(true);
          if (chapterState?.data?.status === "draft") {
            if (isAutosave) {
              onSubmitCreate(false);
            } else {
              setNavigate(`/edit-story/${storyId}`, { replace: true });
            }
          } else {
            onSubmitCreate(true, false, true);
          }
        }}
        isLoadingExit={
          (actionChapter.isLoading && isPublish) || myStory.isLoading
        }
        isLoading={(actionChapter.isLoading && !isPublish) || myStory.isLoading}
      />

      <ConfirmSetTopUpModal
        isOpen={confirmTopupModal}
        setOpen={setConfirmTopupModal}
        onConfirm={() => {
          setConfirmTopupModal(false);
          setTopupModal(true);
        }}
        onCancel={() => {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }}
      />
      <SetTopUpModal
        isOpen={TopupModal}
        setOpen={setTopupModal}
        chapterId={actionChapterState?.data?.chapter_id}
        onCancel={() => {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }}
        onSuccess={() => {
          resetMedia();
          setNavigate(`/edit-story/${storyId}`, { replace: true });
        }}
      />

      <ModalAlert
        show={isDialogMonetizeShow}
        onHide={() => setDialogMonetizeShow(false)}
        description="Cerita ini telah dimonetisasi, untuk dapat melakukan fungsi ini silahkan hubungi admin"
      />
      <Navbar
        bg="white"
        style={{ borderBottom: "2px solid #e6e8eb" }}
        sticky="top"
      >
        <Container>
          <ButtonGroup>
            <IconButton
              faIcon={faArrowLeft}
              onClick={() =>
                navigate(`/edit-story/${storyId}`, { replace: true })
              }
            />
            <Sizedbox width="10px" />
            <Navbar.Text>
              <p className="title-appbar">Edit Bab</p>
            </Navbar.Text>
          </ButtonGroup>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!isMobile && (
              <>
                <CustomButton
                  size="sm"
                  onClick={() => {
                    setPublish(false);
                    if (!isPublish && myStory.data.monetize) {
                      setDialogMonetizeShow(true);
                    } else {
                      onSubmitCreate(false);
                    }
                  }}
                  isLoading={
                    (actionChapter.isLoading && !isPublish) || myStory.isLoading
                  }
                  variant="secondary"
                >
                  Draf
                </CustomButton>
                <div style={{ width: "10px" }}></div>
                <CustomButton
                  size="sm"
                  isLoading={
                    (actionChapter.isLoading && isPublish) || myStory.isLoading
                  }
                  onClick={() => {
                    setPublish(true);
                    onSubmitCreate(true);
                  }}
                >
                  Terbitkan
                </CustomButton>
              </>
            )}
            <CircleProfile />
          </div>
        </Container>
      </Navbar>

      {isMobile && (
        <Navbar fixed="bottom" bg="white">
          <Container fluid>
            <CustomButton
              className="w-100"
              onClick={() => {
                setPublish(false);
                if (!isPublish && myStory.data.monetize) {
                  setDialogMonetizeShow(true);
                } else {
                  onSubmitCreate(false);
                }
              }}
              isLoading={
                (actionChapter.isLoading && !isPublish) || myStory.isLoading
              }
              variant="secondary"
            >
              Draf
            </CustomButton>
            <div style={{ width: "20px" }}></div>
            <CustomButton
              className="w-100"
              isLoading={
                (actionChapter.isLoading && isPublish) || myStory.isLoading
              }
              onClick={() => {
                setPublish(true);
                onSubmitCreate(true);
              }}
            >
              Terbitkan
            </CustomButton>
          </Container>
        </Navbar>
      )}

      <Container>
        <Card className={`my-4 ${isMobile ? "p-2" : "p-4"}`}>
          <Card.Body>
            <PopupMenuChapter
              isMonetize={
                myStory?.data?.monetize &&
                chapterState?.data?.full_text_draft === ""
              }
              isDeleteBtn={true}
              fullText={content.current}
              title={title.current.value}
              setNavigate={(val) => setNavigate(val)}
              type={selectedTab}
            />

            <FormBootstrap.Group className="mb-3">
              <FormBootstrap.Label>Judul Bab</FormBootstrap.Label>
              <FormBootstrap.Control
                placeholder="Tulis judul bab"
                //defaultValue={title.current.value}
                maxLength="100"
                ref={title}
                onChange={(e) => {
                  const counterTitle = document.querySelector(".counter-title");
                  counterTitle.textContent = `${title.current.value.length} / 100`;
                  if (e.target.value !== "") {
                    setBlocking(true);
                  } else {
                    setBlocking(false);
                  }
                }}
              />
              <FormBootstrap.Text
                className="text-muted counter-title"
                style={{ display: "inline-block", float: "right" }}
              >
                0 / 100
              </FormBootstrap.Text>
            </FormBootstrap.Group>
            <Nav
              className="my-4"
              style={{ display: "flex", justifyContent: "center" }}
              onSelect={(selectedKey) => {
                setSelectedTab(selectedKey);
              }}
              defaultActiveKey={selectedTab}
            >
              <Nav.Item>
                <Nav.Link eventKey="text">Teks</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pdf">PDF</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="image">Gambar</Nav.Link>
              </Nav.Item>
            </Nav>
            {selectedTab === "text" && (
              <>
                {autosaveState.isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <img
                    alt="autosave"
                    src="/assets/AUTOSAVE.png"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                    width="20px"
                    height="20px"
                  />
                )}
                <FormBootstrap.Text
                  style={{ display: "inline-block", margin: "auto 10px" }}
                >
                  Autosave
                </FormBootstrap.Text>
                <SizedBox height="20px" />
                <CKEditor
                  editor={Editor}
                  data={content.current}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "min-height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                    editor.plugins
                      .get("WordCount")
                      .on("update", (evt, stats) => {
                        const wordCount = document.querySelector(".word-count");
                        wordCount.textContent = stats.words;
                        wordsCount.current = stats.words;
                      });
                    if (isFirstInitChapter) {
                      dispatch(getMyStory(storyId));
                      dispatch(
                        getEditChapter({
                          storyId: storyId,
                          chapterId: chapterId,
                        })
                      );
                    }
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    content.current = data;
                    if (stripHtml(data).result !== "" && !isBlocking) {
                      setBlocking(true);
                    } else if (stripHtml(data).result === "" && isBlocking) {
                      setBlocking(false);
                    }
                    let dataLength = stripHtml(data).result.length;

                    if (!isautosaveStart && dataLength >= 50) {
                      setAutoSaveStart(true);
                      autoSave();
                    }
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
                <FormBootstrap.Text>
                  Kata: &nbsp; <FontAwesomeIcon icon={faPlusMinus} /> &nbsp;
                </FormBootstrap.Text>
                <FormBootstrap.Text className="word-count">
                  0
                </FormBootstrap.Text>
              </>
            )}
            {selectedTab === "pdf" && (
              <PdfChapter
                setMedia={(val) => {
                  if (val.length > 0) {
                    setBlocking(true);
                  } else {
                    setBlocking(false);
                  }
                  setMediaPdf(val);
                }}
                pdf={
                  chapterState?.data?.type === "pdf"
                    ? chapterState?.data?.media
                    : []
                }
              />
            )}
            {selectedTab === "image" && (
              <ImageChapter
                setMedia={(val) => {
                  if (val.length > 0) {
                    setBlocking(true);
                  } else {
                    setBlocking(false);
                  }
                  setMediaImage(val);
                }}
                images={
                  chapterState?.data?.type === "image"
                    ? chapterState?.data?.media
                    : []
                }
              />
            )}
          </Card.Body>
        </Card>
        {isMobile && <SizedBox height="70px" />}
      </Container>
    </>
  );
};

export default EditChapter;
