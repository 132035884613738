
import { faAngleLeft, faAngleRight, faBars, faGear, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  useState } from 'react';
import { Navbar,Button,Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom';


const NavbarReading = ({...props}) => {

  const [isLike,setLike] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true);

  return (
    <Navbar fixed="bottom" className="bg-white">
        <Link to="/book/read/er/er" className="form-control text-center border-0 shadow-none"><FontAwesomeIcon icon={faAngleLeft} /></Link>
        <Button variant="light" className="form-control">
        <FontAwesomeIcon icon={faBars} />
        </Button>
        <Button variant="light" className="form-control btn-sm btn-padding-0" onClick={() => setLike(!isLike)}>
          {isLike ? (<FontAwesomeIcon icon={faHeart} />) : (<FontAwesomeIcon icon={faHeart} />)}
       
       
        </Button>
        <Button variant="light" className="form-control" onClick={handleShow}>
        <FontAwesomeIcon icon={faGear} />
        </Button>
        <Link to="/book/read/se/ak" className="form-control text-center border-0 shadow-none"><FontAwesomeIcon icon={faAngleRight} /></Link>
        <Offcanvas show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Pengaturan</Offcanvas.Title>
        </Offcanvas.Header>
        {/* <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body> */}
      </Offcanvas>
    </Navbar>
  )
}

export default NavbarReading