import React from 'react'
import {Row} from 'react-bootstrap';
import MyStoryEmpty from '../components/myStoryEmpty';
import MyStoryError from '../components/myStoryError';
import MyStoryShimmer from '../components/myStoryShimmer';
import ItemStoryDraft from './itemStoryDraft';

const MyStoryDraft = ({myStories,onError}) => {
  return (
    <>
      {myStories.isLoading && <MyStoryShimmer length={10} /> }
      {!myStories.isLoading && myStories.status && myStories.data.length===0 && <MyStoryEmpty />}
      {!myStories.isLoading && myStories.status && (
        <Row>
          {myStories.data.map((story,i) => (
           <ItemStoryDraft key={i} data={story} i={i}/>
           ))}
        </Row>
      )} 
      {!myStories.isLoading && !myStories.status && <MyStoryError onError={onError}/> }
    </>
      
  )
}

export default MyStoryDraft