import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import myStoryService from "../../services/myStoryService";

const name = 'MyStories';
export const getMyStoriesDraft = createAsyncThunk(
    `${name}/draft`, async ({pageNumber}) => {
        return await myStoryService.getMyStoriesDraft({pageNumber: pageNumber});
    }
)

export const getMyStoriesRelease = createAsyncThunk(
    `${name}/release`, async ({pageNumber}) => {
        return await myStoryService.getMyStoriesRelease({pageNumber: pageNumber});
    }
)

const myStorySlice = createSlice({
    name: name,
    initialState: {
        isLoading: false,
        status: false,
        message: "",
        isLoadMore: false,
        data: [],
    },
    reducers: {
        resetMyStories: (state) =>{
            state.isLoading = false;
            state.status = false;
            state.message = "";
            state.isLoadMore = false;
            state.data = [];
        }
    },
    extraReducers: {
        //Draft
        [getMyStoriesDraft.pending](state){
            state.isLoading = true;
            state.status = false;
            state.message = "";
            state.isLoadMore = false;
        },
        [getMyStoriesDraft.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.message = payload.message;
            state.isLoadMore = payload.data.is_loadmore;
            state.data.push(...payload.data.draft);
        },
        [getMyStoriesDraft.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            state.message = error.message
        },
        //Release
        [getMyStoriesRelease.pending](state){
            state.isLoading = true;
            state.status = false;
            state.message = "";
            state.isLoadMore = false;
        },
        [getMyStoriesRelease.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.message = payload.message;
            state.isLoadMore = payload.data.is_loadmore;
            state.data.push(...payload.data.release);
        },
        [getMyStoriesRelease.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            state.message = error.message
        },
    }
});

export const {resetMyStories} = myStorySlice.actions;

export default myStorySlice.reducer;