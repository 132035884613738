import {useDropzone} from 'react-dropzone';
import { useEffect, useMemo } from 'react';
import './style.css';
import Sizedbox from '../../../../utils/components/sizedbox';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Spinner } from 'react-bootstrap';
import { setDataUploadChapterImage, uploadChapterImage } from '../../../../features/upload/uploadChapterImg';
import ItemImageChapter from './itemImageChapter';
import { removeIndexChapterImage } from '../../../../features/upload/uploadChapterImg';
import CustomButton from '../../../../utils/components/customButton';
import { toast } from 'react-toastify';
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    innerHeight: '200px',
    outerHeight:'500px',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


const ImageChapter = ({setMedia,images}) => {
  let mediaAlreadyUpload = [];
  const uploadImageState = useSelector(state => state.uploadChapterImage);
  const dispatch = useDispatch();
    const {acceptedFiles, 
        getRootProps,
        open,
        fileRejections,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject} = useDropzone({
        multiple:true,
        noClick:true,
        noKeyboard: true,
        accept:'.png,.jpg,.jpeg',
        maxSize: 3 * (1024 * 1024),
        maxFiles: 20,
    });

    useEffect(() => {
      if(images.length > 0){
        const params = images.map(image => ({
          'status': true,
          'message': "Berhasil mengunggah berkas",
          'data': {
            '_id': image._id,
            'url': image.url,
            'owner':'',
          }
        }));
        
        dispatch(setDataUploadChapterImage(params));
      }
    },[images,dispatch]);
  
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);

    useEffect(() => {
      if(uploadImageState.data.length >= 0){
        setMedia(uploadImageState.data.map(image => image.data._id));
      }
    },[uploadImageState]);

    useEffect(() => {
      if(acceptedFiles.length > 0){
        let imageUpload = acceptedFiles.filter(file => !mediaAlreadyUpload.includes(file));
        dispatch(uploadChapterImage(imageUpload))
        .then(unwrapResult)
        .then((_) => {
          mediaAlreadyUpload.push(...imageUpload);
        });
      }
    },[acceptedFiles,dispatch]);

    useEffect(() => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(err => {
          switch(err.code){
            case 'file-invalid-type':
              toast.error('Gambar harus bertipe .jpg,.png,.jpeg',{theme:'colored'});
              break;  
            case 'file-too-large':
              toast.error('Gambar maksimal 3 mb',{theme:'colored'});
              break;  
            case 'too-many-files':
              toast.error('Upload gambar maksimal 20',{theme:'colored'});
              break;  
            default:
              toast.error(err.message,{theme: 'colored'});
          }
          
        } );
      })
    },[fileRejections]);

    return (
      <section className="container">
        <div {...getRootProps({style})}>
        <input {...getInputProps()} />
          <Sizedbox height={uploadImageState?.data?.length > 0 ? '20px' : '100px'} />
          {uploadImageState.isLoading 
            ? <Spinner animation="border" className="mb-4"/>
            : uploadImageState?.data?.length > 0
              ? uploadImageState?.data?.map((image,index) => (
                <ItemImageChapter 
                  index={index}  
                  onDelete={(i) => dispatch(removeIndexChapterImage(i))} 
                  src={image.data.url}/>)) 
              : <p style={{textAlign:'center'}}>
                  Drag 'n' drop gambar disini atau klik tombol dibawah untuk memilih gambar
                </p>
            
          }
          <CustomButton onClick={open}>Pilih Gambar</CustomButton>
          <Sizedbox height={uploadImageState?.data?.length > 0 ? '20px' : '100px'} />
        </div>
        
      </section>
    );
}

export default ImageChapter