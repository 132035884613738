import React, { useEffect, useRef, useState, useCallback } from "react";
import { Card, Form as FormBootstrap } from "react-bootstrap";
import CustomTextField from "../../../utils/components/customTextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  login,
  loginGoogle,
  postloginGoogle,
  validationRecaptcha,
} from "../../../features/auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomButton from "../../../utils/components/customButton";
import Sizedbox from "../../../utils/components/sizedbox";
import ReCAPTCHA from "react-google-recaptcha";
import { isMobile } from "react-device-detect";
import { SessionKey } from "../../../utils/values/constants";
import Cookies from "js-cookie";
import { decryptCookies } from "../../../utils/helpers/cryptoHelper";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const loginSchema = Yup.object().shape({
  username: Yup.string().required("Nama Pengguna/Email wajib diisi!"),
  password: Yup.string()
    .required("Kata sandi wajib diisi!")
    .min(8, "Kata sandi minimal 8 karakter"),
});

const LoginPage = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [btnType, setBtnType] = useState("username");
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const userId = decryptCookies(Cookies.get(SessionKey.userId));
    if (userId) {
      window.location.replace("/");
    }
  }, []);
  const onLoginGoogle = () => {
    setBtnType("google");
    dispatch(loginGoogle())
      .then(unwrapResult)
      .then((data) => {
        dispatch(
          postloginGoogle({
            email: data.user.email,
            token: data.user.stsTokenManager.accessToken,
            uid: data.user.uid,
            name: data.user.displayName,
            profileImg: data.user.photoURL,
          })
        );
      });
  };

  const handleReCaptchaVerify = useCallback(
    async ({ username, password }) => {
      if (!executeRecaptcha) {
        // console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha();
      dispatch(validationRecaptcha({ token: token }))
        .then(unwrapResult)
        .then((data) => {
          dispatch(login({ username: username, password: password }));
        })
        .catch((err) => {});
      // Do whatever you want with the token
    },
    [executeRecaptcha]
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Card className="p-4" style={{ width: isMobile ? "100%" : "35%" }}>
        <h4 className="text-center">Masuk</h4>
        <Sizedbox height="20px" />
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            setBtnType("username");
            handleReCaptchaVerify({
              username: values.username,
              password: values.password,
            });
          }}
        >
          {(props) => (
            <Form>
              <CustomTextField name="username" label="Nama Pengguna / Email" />

              <CustomTextField
                name="password"
                label="Kata sandi"
                type="password"
              />

              <Sizedbox height="20px" />
              <CustomButton
                className="w-100"
                disabled={authState.isLoading && btnType === "username"}
                isLoading={authState.isLoading && btnType === "username"}
                onClick={props.handleSubmit}
              >
                Masuk
              </CustomButton>
              <Sizedbox height="20px" />
              <CustomButton
                className="w-100"
                disabled={authState.isLoading && btnType === "google"}
                isLoading={authState.isLoading && btnType === "google"}
                variant="secondary"
                onClick={onLoginGoogle}
              >
                <img
                  src="/assets/GOOGLE.png"
                  alt="Google"
                  width="20px"
                  style={{ margin: "auto 10px" }}
                />
                Google
              </CustomButton>
            </Form>
          )}
        </Formik>
        <Sizedbox height="10px" />
        <FormBootstrap.Text style={{ fontSize: "12px", fontWeight: 600 }}>
          *Harap tidak melakukan pengeditan secara bersamaan dalam aplikasi dan
          web menulis, untuk menghindari kesalahan dalam penyimpanan konten.
        </FormBootstrap.Text>
      </Card>
    </div>
  );
};

export default LoginPage;
