import SizedBox from '../../../utils/components/sizedbox';
const ItemMenuEditMyStory = ({src,title,textColor = '#20BF6B',onClick,disabled}) => {
  return (
    <>
        <div 
        style={{
            display:'grid',
            gridTemplateColumns: '25px 10px auto',
            gridTemplateRows: '25px',
            cursor: disabled ? 'not-allowed' : 'pointer',
            }}
        onClick={disabled ? null : onClick}>
            <img src={src} alt="ima" width="25px" height="25px" style={{ filter: disabled ? 'invert(100%)' : ''}} /> 
            <SizedBox width="10px"/>
            <h6 style={{color: disabled ? '#929AA7' : textColor ,margin: 'auto 0'}}>{title}</h6>
        </div>
    </>
  )
}

export default ItemMenuEditMyStory