import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import chapterService from "../../services/chapterService";

const name = 'autosave';

export const autosave = createAsyncThunk(
    `${name}/autosave`, async ({title,storyId,status,saveDraft,fullText,type,chapterId = ''}) => {
        return await chapterService.autoSave({
            title: title,
            storyId:storyId,
            status: status,
            saveDraft: saveDraft,
            fullText: fullText,
            type: type,
            chapterId:chapterId,
        });
    }
)

const autosaveSlice = createSlice({
    name: name,
    initialState:{
        isLoading: false,
        status: false,
        data: null,
    },
    reducers: {
        resetAutosave: (state,{payload}) => {
            state.isLoading = false;
            state.status = false;
            state.data = null;
        },
    },
    extraReducers: {
        [autosave.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [autosave.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            if(state.data===null){
                state.data = payload.data.chapter;
            }
        },
        [autosave.rejected](state){
            state.isLoading = false;
            state.status = false;
        },
    }
})
export const {resetAutosave} = autosaveSlice.actions;
export default autosaveSlice.reducer;