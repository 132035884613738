import React, { useState, useEffect, useCallback } from "react";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import {Modal} from 'react-bootstrap';

import CustomButton from "../../../../../utils/components/customButton";
import { useBlocker } from "../../../../../utils/helpers/useBlocker";
import IconButton from "../../../../../utils/components/iconButton";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ExitConfirmChapterModal = ({ isBlocking, isLoading,onExit,isLoadingExit = false, onSave,errors }) => {

  function useCallbackPrompt(when) {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
      setShowPrompt(false);
    }, []);

    const handleBlockedNavigation = useCallback(
      nextLocation => {
        if (
          !confirmedNavigation &&
          nextLocation.location.pathname !== location.pathname
        ) {
          setShowPrompt(true);
          setLastLocation(nextLocation);
          return false;
        }
        return true;
      },
      [confirmedNavigation]
    );

    const confirmNavigation = useCallback(() => {
      setShowPrompt(false);
      setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        navigate(lastLocation.location.pathname);
      }
    }, [confirmedNavigation, lastLocation]);

    useBlocker(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
  }

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isBlocking
  );
  return (
    <Modal
    show={showPrompt}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Konfirmasi
      </Modal.Title>
      <IconButton faIcon={faXmark} className="mx-2" onClick={cancelNavigation} />
    </Modal.Header>
    <Modal.Body>
      <p>Tulisan anda mungkin belum tersimpan. Apakah anda ingin menyimpan tulisan terlebih dahulu?</p>
    </Modal.Body>
    <Modal.Footer>
      <CustomButton 
        className="w-100" 
        variant="primary" 
        isLoading={isLoading} 
        onClick={onSave} >Ya, simpan sebagai draf</CustomButton>
      <CustomButton 
        className="w-100" 
        variant="secondary" 
        isLoading={isLoadingExit}
        onClick={() => {
          if(onExit){
            onExit();
          }else{
            confirmNavigation();
          }
        }}>
          Tidak, keluar tanpa simpan
        </CustomButton>
     
    </Modal.Footer>
  </Modal>
  );
}

export default ExitConfirmChapterModal;