import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import uploadService from "../../services/uploadService";

const name = 'uploadCover';

export const uploadCover = createAsyncThunk(
    `${name}/cover`,async (imageFile) => {
    return await uploadService.coverStory(imageFile);
});


const uploadCoverSlice = createSlice({
    name: name,
    initialState: {
        isLoading: false,
        status: false,
        message: '',
        data: {},
    },
    reducers: {
        resetUploadCover: (state) =>{
            state.isLoading = false;
            state.status = false;
            state.data = {};
        }
    },
    extraReducers: {

        [uploadCover.pending](state){
            state.isLoading = true;
        },
        [uploadCover.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = payload[0].status;
            state.data = payload[0].data;
        },
        [uploadCover.rejected](state,{error}){
            state.isLoading = false;
            state.status = error.status;
        }
    }
})

export const {resetUploadCover} = uploadCoverSlice.actions;

export default uploadCoverSlice.reducer;