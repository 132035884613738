import { Outlet } from 'react-router';
import MaintenancePage from '../../pages/customPage/maintenancePage';
const MaintenanceRoute = () => {
    let isMaintenance = process.env.REACT_APP_IS_MAINTENANCE;
    if(isMaintenance==='1')
        return <MaintenancePage/> 
    else 
        return <Outlet/>;
    
}

export default MaintenanceRoute