import MyAxios from "../utils/helpers/myAxios";
import { ErrorResponse } from "../utils/values/constants";

const uploadService = (() => {

    const coverStory = async (imageFile) => {
        try{
            const formData = new FormData();
            formData.append('file',imageFile);
            MyAxios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            const response = await MyAxios.post('/upload/story/cover_img',formData);
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    }

    const chapterPdf = async (imageFile) => {
        try{
            const formData = new FormData();
            formData.append('file',imageFile);
            MyAxios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            const response = await MyAxios.post('/upload/chapter/pdf',formData);
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    }

    const chapterImage = async (imageFiles) => {
        try{
            const formData = new FormData();
            imageFiles.forEach(file => {
                formData.append('file',file);
            });
            MyAxios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            const response = await MyAxios.post('/upload/chapter/img',formData);
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    }

    return {
        coverStory: coverStory,
        chapterPdf: chapterPdf,
        chapterImage: chapterImage,
    }

})();

export default uploadService;