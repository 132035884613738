
import { useSelector } from 'react-redux'
import {Modal} from 'react-bootstrap';
import CustomButton from '../../utils/components/customButton';

const TermsMonetize = ({storyId,onAgree,...props}) => {
    const monetizeState = useSelector(state => state.actionMyStory);

  return (
    <Modal
    {...props}
    fullscreen
    scrollable
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
       Syarat & Ketentuan Monetisasi
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <iframe title="writing" src="https://kbm.id/pages/monetize.html" allowFullScreen={true} width="100%" height="100%" ></iframe>
    </Modal.Body>
    <Modal.Footer>
        <CustomButton isLoading={monetizeState.isLoading} onClick={onAgree}>Setuju</CustomButton>
    </Modal.Footer>
  </Modal>
  )
}

export default TermsMonetize