import axios from "axios";
import Cookies from "js-cookie";
import { SessionKey } from "../values/constants";
import { decryptCookies } from "./cryptoHelper";

const MyAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      decryptCookies(Cookies.get(SessionKey.token)) != null
        ? `Bearer ${decryptCookies(Cookies.get(SessionKey.token))}`
        : null,
  },
});

export default MyAxios;
