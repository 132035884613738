import React from 'react'
import {isMobile} from 'react-device-detect';
import CustomButton from '../../../utils/components/customButton';
import Sizedbox from '../../../utils/components/sizedbox';
const MyStoryError = ({onError}) => {
  return (
            <div style={{display:'flex',flexFlow:'column',alignItems:'center',margin: '50px 0'}}>
                <img alt='lost-connection' src="/assets/LOST_CONNECTION.png" width={isMobile ? "50%" : "25%"}/>
                <Sizedbox height="20px" />
                <h5 style={{color: "#20BF6B"}}>Ada Kesalahan Teknis</h5>
                <Sizedbox height="20px" />
                <CustomButton onClick={onError}>Coba lagi</CustomButton>
            </div>
  )
}

export default MyStoryError