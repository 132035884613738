import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import uploadService from "../../services/uploadService";


const name = 'uploadChapterImage';

export const uploadChapterImage = createAsyncThunk(
    `${name}/Image`,async (ImageFile) => {
        return await uploadService.chapterImage(ImageFile);
    }
)

const uploadChapterImageSlice = createSlice({
    name: name,
    initialState: {
        isLoading: false,
        status: false,
        data: [],
    },
    reducers:{
        removeIndexChapterImage: (state,action) => {
            state.data.splice(action.payload,1);
        },
        resetUploadChapterImage: (state) => {
            state.data = [];
            state.isLoading = false;
            state.status = false;
        },
        setDataUploadChapterImage: (state,{payload}) => {
            state.data.push(...payload);
        },
    },
    extraReducers: {
        [uploadChapterImage.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [uploadChapterImage.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data.push(...payload);
        },
        [uploadChapterImage.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message,{theme:'colored'});
        },
    }
});

export const {
    removeIndexChapterImage,
    resetUploadChapterImage,
    setDataUploadChapterImage,
} = uploadChapterImageSlice.actions;

export default uploadChapterImageSlice.reducer;