import MyAxios from "../utils/helpers/myAxios";
import { ErrorResponse } from "../utils/values/constants";

const masterService = (() => {

    const getCover = async () => {
        try{
            const response = await MyAxios.get('/api/master/cover');
            if(!response.data.status){
                return Promise.reject(response.data);
            }
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    }

    const getCategory = async () => {
        try{
            const response = await MyAxios.get('/api/master/category/');
            if(!response.data.status){
                return Promise.reject(response.data);
            }
            return response.data
        }catch(e){
            throw ErrorResponse;
        }
    }

    return {
        getCategory: getCategory,
        getCover:getCover
    }

})();
export default masterService;