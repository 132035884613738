import { ErrorResponse, SessionKey } from "../utils/values/constants";
import MyAxios from "../utils/helpers/myAxios";
import Cookies from "js-cookie";
import { decryptCookies } from "../utils/helpers/cryptoHelper";
const profileService = (() => {
  const getMyProfile = async () => {
    try {
      const userId = decryptCookies(Cookies.get(SessionKey.userId));
      const response = await MyAxios.get(`/api/profile/${userId}`);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response.data;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  return {
    getMyProfile: getMyProfile,
  };
})();

export default profileService;
