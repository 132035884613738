import React from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../../../utils/components/customButton'

const ConfirmSetTopUpModal = ({
    isOpen,
    setOpen,
    onConfirm,
    onCancel,
}) => {
  return (
    <Modal
    show={isOpen}
    onHide={() => setOpen(false)}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    style={{
        borderRadius: '1000px !important'
    }}
  >
    <Modal.Body
        style={{
            borderRadius: '1000px'
        }}>
             <div
        style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'stretch',
            padding: '18px'
        }}
        className='flex flex-col items-stretch p-6'>
          <img 
            alt='lock-yellow'
            className='mx-auto'
            src='/assets/icons/lock-yellow.svg' />
        <p 
            style={{
                fontSize: '18px',
                color: '#333333',
                paddingTop: '16px',
                textAlign: 'center',
            }}
            >
            Apakah Anda ingin mengunci bab ini?
        </p>
        <div style={{
            display: 'flex',
            
            justifyContent: 'center',
        }}>
            <CustomButton
                onClick={() => {
                    setOpen(false);
                    onCancel();
                }}
                variant='secondary'
                styles={{
                    minWidth: '100px'
                }}
             >
                Batal
             </CustomButton>
             <div style={{width: '12px'}} />
             <CustomButton
                styles={{
                    minWidth: '100px'
                }}
                onClick={onConfirm}
             >
                Ya
             </CustomButton>
        </div>
    </div>

    </Modal.Body>
   
  </Modal>
  )
}

export default ConfirmSetTopUpModal