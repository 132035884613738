import {Placeholder} from 'react-bootstrap';
import React from 'react';
import _ from "lodash";
import {Col,Row,Card} from 'react-bootstrap';
import {isMobile} from 'react-device-detect';
const MyStoryShimmer = ({length = 1}) => {
 return (
    <Row>

        {_.times(length, (i) => (
            <Col md={6} key={i}>
            <Card className="mb-4 item-story">
              <Card.Body className="pl-2 ">
                  <div style={{display:"grid", gridTemplateColumns: "27.5% 72.5%"}}>
                    <Placeholder style={{width:"100%", height:isMobile ? '120px' : '196px',borderRadius:'5px'}}/>
                    <div className="px-4" style={{display:'flex',flexFlow: 'column',justifyContent: 'space-between'}}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </div>
                  </div>
              </Card.Body>
            </Card>
          </Col>
          ))}
    </Row>
  )
}

export default MyStoryShimmer