import CryptoJS from "crypto-js";

export const encryptCookies = (value) => {
  if (!value) {
    return undefined;
  }
  const secretKey = "zorku7-suttyM-nannam";
  const iv = CryptoJS.enc.Hex.parse("qujkoh-5qoqgy-cAvjyk");
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey, {
    iv,
  });
  return encryptedData;
};

export const decryptCookies = (value) => {
  if (!value) {
    return undefined;
  }
  const secretKey = "zorku7-suttyM-nannam";
  const iv = CryptoJS.enc.Hex.parse("qujkoh-5qoqgy-cAvjyk");
  const decryptedData = CryptoJS.AES.decrypt(value, secretKey, {
    iv,
  }).toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(decryptedData);
  } catch (err) {
    return decryptedData;
  }
};
