import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import uploadService from "../../services/uploadService";

const name = 'uploadChapterPdf';

export const uploadChapterPdf = createAsyncThunk(
    `${name}/pdf`,async (pdfFile) => {
        return await uploadService.chapterPdf(pdfFile);
    }
)

const uploadChapterPdfSlice = createSlice({
    name: name,
    initialState: {
        isLoading: false,
        status: false,
        originName: null,
        data: null,
    },
    reducers: {
        resetUploadChapterPdf: (state) =>{
            state.isLoading = false;
            state.status = false;
            state.data = null;
            state.originName = null;
        },
        setDataUploadChapterPdf: (state,{payload}) => {
            state.isLoading = false;
            state.status = false;
            state.data = payload;
        },
        setPdfOriginName: (state,{payload}) => {
            state.originName = payload;
        }
    },
    extraReducers: {
        [uploadChapterPdf.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [uploadChapterPdf.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data = payload;
        },
        [uploadChapterPdf.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message,{theme:'colored'});
        },
    }
});

export const {resetUploadChapterPdf,setDataUploadChapterPdf,setPdfOriginName} = uploadChapterPdfSlice.actions;
export default uploadChapterPdfSlice.reducer;