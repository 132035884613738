
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDgMRF9YqKoA48FsBlFw0ta1208ESwBs48",
  authDomain: "ketix-apps.firebaseapp.com",
  databaseURL: "https://ketix-apps.firebaseio.com",
  projectId: "ketix-apps",
  storageBucket: "ketix-apps.appspot.com",
  messagingSenderId: "935968497295",
  appId: "1:935968497295:web:b6b06b8ed6f4dec2191afa",
  measurementId: "G-0DCQ01SXB0"
};
