import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import chapterService from "../../services/chapterService";

const name = 'chapter';
export const getCostUnlockChapter = createAsyncThunk(
    `${name}/chapter/getCostUnlockChapter`,async ({chapterId}) =>{
    return await chapterService.getCostUnlockChapter({
        chapterId: chapterId,
    })
});
let toastId;
const costUnlockChapterSlice = createSlice({
    name: name,
    initialState: {
        isLoading :false,
        status : false,
        data: null,
    },
    reducers: {
    },
    extraReducers: {
        [getCostUnlockChapter.pending](state){
            state.isLoading = true;
            state.status = false;
            state.data = null;
            toastId = toast.loading('Memuat data');
        },
        [getCostUnlockChapter.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = false;
            state.data = payload.data.chapter;
            toast.dismiss();
        },
        [getCostUnlockChapter.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        },
    }
});


export default costUnlockChapterSlice.reducer;