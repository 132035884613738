import React from 'react'
import Sizedbox from '../../../../../utils/components/sizedbox'

const IconText = ({imagePath,text}) => {
  return (
    <div style={{display: 'flex',alignItems:'center'}}>
       
        <img alt={imagePath} src={imagePath} />
        <Sizedbox width="10px"/>
        <p style={{
            display:'inline-block',
            margin: 'auto 0',
            color: '#929AA7',
            fontSize: '13px',
        }}>{text}</p>
    </div>
  )
}

export default IconText