import MyAxios from '../utils/helpers/myAxios';
import { ErrorResponse } from "../utils/values/constants";

const termsService = (() => {

    const getStatusTerms = async () => {
        try{
            const response = await MyAxios.get('/api/user/status/term-of-condition');
            if(!response.data.status){
                return Promise.reject(response.data);
            }
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    }

    const postAgreeTerms = async () => {
        try{
            const response = await MyAxios.post('/api/user/aggree/term-of-condition');
            if(!response.data.status){
                return Promise.reject(response.data);
            }
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    }

    return {
        getStatusTerms: getStatusTerms,
        postAgreeTerms:postAgreeTerms,
    }
})()

export default termsService;