import axios from "axios"
import { ErrorResponse } from "../utils/values/constants";

const otherService = (() => {
    
    const postDynamicLink = async () => {
        try{
            const params = {
                dynamicLinkInfo: {
                    domainUriPrefix: "https://sh.kbm.id",
                    link: "https://read.kbm.id/",
                    androidInfo: {
                        androidPackageName: "com.ketix.application"
                    },
                    iosInfo: {
                        iosBundleId: "id.kbm.application"
                    }
                }
            }
            axios.defaults.headers.common['Content-Type'] = 'application/json';
            const response = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDgMRF9YqKoA48FsBlFw0ta1208ESwBs48',params);
            return response.data;
        }catch(e){
            throw ErrorResponse;
        }
    } 

    return {
        postDynamicLink: postDynamicLink,
    }
})();

export default otherService;