import { useParams } from "react-router";
import { getMyStory } from "../../../features/myStory/myStorySlice";
import {
  Navbar,
  Container,
  Card,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../utils/components/customTextField";
import { useEffect, useState } from "react";
import KbmCoverModal from "./kbmCoverModal";
import { useFilePicker } from "use-file-picker";
import { useDispatch, useSelector } from "react-redux";
import { uploadCover } from "../../../features/upload/uploadCoverSlice";
import CoverBook from "../../../utils/components/coverBook";
import CustomSelect from "../../../utils/components/customSelect";
import CustomTag from "../../../utils/components/customTag";
import { toast } from "react-toastify";
import { upsertStory } from "../../../features/myStory/actionMyStorySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router";
import { getCategory } from "../../../features/master/categorySlice";
import ListChapterMyStory from "./listChapterMyStory";
import CustomButton from "../../../utils/components/customButton";
import Sizedbox from "../../../utils/components/sizedbox";
import PopupMenuEditMyStory from "./popupMenuEditMyStory";
import IconButton from "../../../utils/components/iconButton";
import CircleProfile from "../../../utils/components/circleProfile";
import { isMobile } from "react-device-detect";
import { resetChapter } from "../../../features/chapter/chapterSlice";
import { resetActionChapter } from "../../../features/chapter/actionChapter";
import ConfirmSetTopUpModal from "./components/confirmSetTopUpModal";
import SetTopUpModal from "./components/setTopupModal";
import {
  getMyListChapter,
  resetMyListChapter,
} from "../../../features/chapter/myListChapterSlice";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const createSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Kolom judul cerita wajib diisi!")
    .max(150, "Kolom judul maksimal 150 karakter"),
  synopsis: Yup.string()
    .trim()
    .required("Kolom sinopsis wajib diisi!")
    .min(50, "Sinopsis minimal 50 karakter")
    .max(5000, "Sinopsis maksimal 5000 karakter"),
  category: Yup.string().required("Kolom pilih kategori wajib diisi"),
  tag: Yup.array(),
});

const EditMyStory = () => {
  const dispatch = useDispatch();
  const { storyId } = useParams();
  const navigate = useNavigate();
  // state
  const [modalShow, setModalShow] = useState(false);
  const [confirmTopupModal, setConfirmTopupModal] = useState(false);
  const [TopupModal, setTopupModal] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [coverId, setCoverId] = useState(null);
  const myListChapterState = useSelector((state) => state.myListChapter);
  const [selectedChapterId, setSelectedChapterId] = useState();
  // library
  const [openFileSelector, { plainFiles, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: [".jpg", ".png", ".jpeg"],
    maxFileSize: 3,
    multiple: false,
  });

  // Selector
  const uploadImage = useSelector((state) => state.uploadCover);
  const category = useSelector((state) => state.category);
  const myStory = useSelector((state) => state.myStory);

  useEffect(() => {
    dispatch(getMyStory(storyId))
      .then(unwrapResult)
      .then((_) => {
        window.scrollTo(0, 0);
      });
    dispatch(resetMyListChapter());
    dispatch(
      getMyListChapter({
        storyId: storyId,
      })
    );
    dispatch(getCategory());
    dispatch(resetChapter());
    dispatch(resetActionChapter());
  }, [dispatch, storyId]);

  useEffect(() => {
    errors.forEach((i) => {
      if (i.fileSizeToolarge) {
        toast.error("Gambar maksimal 3 mb", { theme: "colored" });
      }
    });
  }, [errors]);

  useEffect(() => {
    setCoverId(myStory?.data?.cover_img?._id);
    setCoverImage(myStory?.data?.cover_img?.url);
  }, [myStory]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      if (
        plainFiles[0].type === "image/jpeg" ||
        plainFiles[0].type === "image/jpg" ||
        plainFiles[0].type === "image/png"
      ) {
        dispatch(uploadCover(plainFiles[0]));
      } else {
        toast.error("Gambar harus bertipe .jpg,.jpeg,.png", {
          theme: "colored",
        });
      }
    }
  }, [plainFiles, dispatch]);

  useEffect(() => {
    setCoverImage(uploadImage.data.url);
    setCoverId(uploadImage.data._id);
  }, [uploadImage]);

  const changeKbmCover = (imageId, imageUrl) => {
    setCoverImage(imageUrl);
    setCoverId(imageId);
    setModalShow(false);
  };

  return (
    <>
      <KbmCoverModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        changeCoverKbm={changeKbmCover}
      />
      <ConfirmSetTopUpModal
        isOpen={confirmTopupModal}
        setOpen={setConfirmTopupModal}
        onConfirm={() => {
          setConfirmTopupModal(false);
          setTopupModal(true);
        }}
      />
      <SetTopUpModal
        isOpen={TopupModal}
        setOpen={setTopupModal}
        chapterId={selectedChapterId}
        onSuccess={() => {
          // dispatch(resetMyListChapter());
          // dispatch(getMyListChapter({
          //   storyId: storyId,
          // }))
        }}
      />

      <Formik
        initialValues={{
          title: myStory?.data?.title ?? "",
          synopsis: myStory?.data?.synopsis ?? "",
          category: myStory?.data?.category?._id ?? "",
          tag: myStory?.data?.tags ?? [],
          isShowWordCount:
            myStory?.data?.attribute_settings?.words_count == "active"
              ? true
              : false,
        }}
        enableReinitialize
        validationSchema={createSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (coverId === null) {
            toast.error("Cover buku tidak boleh kosong!", {
              theme: "colored",
            });
            setSubmitting(false);
          } else {
            dispatch(
              upsertStory({
                storyId: storyId,
                title: values.title,
                category: myStory?.data?.category?._id,
                tags: values.tag,
                synopsis: values.synopsis,
                coverImg: coverId,
                isShowWordCount: values.isShowWordCount ? "active" : "disable",
              })
            )
              .then(unwrapResult)
              .then((_) => {
                navigate("/", { replace: true });
              });
          }
        }}
      >
        {(props) => (
          <Form>
            <Navbar
              bg="white"
              expand="lg"
              style={{ borderBottom: "2px solid #e6e8eb" }}
              sticky="top"
            >
              <Container>
                <ButtonGroup>
                  <IconButton
                    faIcon={faArrowLeft}
                    onClick={() => navigate("/", { replace: true })}
                  />
                  <Sizedbox width="10px" />
                  <Navbar.Text>
                    <p size="sm" className="title-appbar">
                      Edit Cerita
                    </p>
                  </Navbar.Text>
                </ButtonGroup>
                <div>
                  {!isMobile && (
                    <CustomButton
                      size="sm"
                      onClick={props.handleSubmit}
                      disabled={myStory.isLoading}
                    >
                      Perbarui
                    </CustomButton>
                  )}
                  <CircleProfile />
                </div>
              </Container>
            </Navbar>

            {isMobile && (
              <Navbar fixed="bottom" bg="white">
                <Container fluid>
                  <CustomButton
                    className="w-100"
                    onClick={props.handleSubmit}
                    disabled={myStory.isLoading}
                  >
                    Perbarui
                  </CustomButton>
                </Container>
              </Navbar>
            )}

            <Container>
              <Card className={`my-4 ${isMobile ? "p-2" : "p-4"}`}>
                <Card.Body>
                  <PopupMenuEditMyStory />
                  <Row>
                    <Col lg={3} className="mx-auto">
                      {coverImage == null && (
                        <div
                          className="mx-auto"
                          style={{
                            width: "202px",
                            height: "282px",
                            backgroundColor: "#E6E8EB",
                            borderRadius: "6px",
                          }}
                        />
                      )}
                      {coverImage != null && (
                        <CoverBook
                          className="mx-auto d-block"
                          src={coverImage}
                          width="202px"
                          height="282px"
                        />
                      )}
                      <DropdownButton
                        disabled={uploadImage.isLoading || myStory.isLoading}
                        title={
                          uploadImage.isLoading ? "Loading..." : "Edit Cover"
                        }
                        className="d-flex justify-content-center mt-4 mb-4"
                      >
                        <Dropdown.Item onClick={() => setModalShow(true)}>
                          Kbm Sampul
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => openFileSelector()}>
                          Galeri
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                    <Col lg={9}>
                      <CustomTextField
                        name="title"
                        label="Judul"
                        disabled={myStory.isLoading}
                        maxLength="150"
                        isCountCharacter
                        placeholder="Tulis judul ceritamu"
                      />
                      <CustomSelect
                        label="Kategori"
                        name="category"
                        isDisabled
                        placeholder="Pilih Kategori cerita"
                        isLoading={category.isLoading}
                        options={category.data.map((val) => ({
                          label: val.name,
                          value: val._id,
                        }))}
                      />
                      <CustomTextField
                        as="textarea"
                        rows={5}
                        name="synopsis"
                        isCountCharacter={true}
                        label="Sinopsis"
                        maxLength="5000"
                        disabled={myStory.isLoading}
                        placeholder="Tulis deskripsi ceritamu"
                      />
                      <CustomTag
                        label="Kata Kunci"
                        disabled={myStory.isLoading}
                        value={props.values.tag}
                        touched={props.touched.tag}
                        onRemove={(val) =>
                          props.setFieldValue(
                            "tag",
                            props.values.tag.filter((tag) => tag !== val)
                          )
                        }
                        placeholder="Tulis kata kunci dan klik ENTER"
                        onEnter={(val) =>
                          props.setFieldValue("tag", [...props.values.tag, val])
                        }
                        errors={props.errors.tag}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "18px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="isShowWordCount"
                          name="isShowWordCount"
                          onChange={(val) => {
                            props.setFieldValue(
                              "isShowWordCount",
                              val.target.checked
                            );
                          }}
                          checked={props.values.isShowWordCount}
                        />
                        <label
                          style={{
                            paddingLeft: "12px",
                            cursor: "pointer",
                            color: "#929AA7",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                          htmlFor="isShowWordCount"
                        >
                          Tampilkan Jumlah Kata {props.values.isShowWordCount}
                        </label>
                      </div>

                      <CustomButton
                        onClick={() =>
                          navigate(`/create-chapter/${myStory.data._id}`)
                        }
                        className="w-100"
                        styles={{ padding: "12px 0" }}
                      >
                        Tambah Bab
                      </CustomButton>
                      <Sizedbox height="20px" />
                      <ListChapterMyStory
                        setSelectedChapterId={setSelectedChapterId}
                        setConfirmTopupModal={setConfirmTopupModal}
                        companyType={myStory.data?.author?.company_type}
                        chapters={myListChapterState.data ?? []}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </Form>
        )}
      </Formik>
      {isMobile && <Sizedbox height="70px" />}
    </>
  );
};

export default EditMyStory;
