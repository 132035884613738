import { Card, Modal } from "react-bootstrap"
import Sizedbox from "../../../../../utils/components/sizedbox"
import IconText from "./iconText";
import parse from 'html-react-parser';
import './style.css';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CustomButton from "../../../../../utils/components/customButton";
import IconButton from "../../../../../utils/components/iconButton";
import {isMobile} from 'react-device-detect';
import { postDynamicLink } from "../../../../../features/other/dynamicLinkSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobileScreen, faTabletScreenButton, faXmark } from "@fortawesome/free-solid-svg-icons";
const PreviewChapter = ({onHide,type,fullText,title,...props}) => {
  const dispatch = useDispatch();
  const uploadPdfState = useSelector(state => state.uploadChapterPdf);
  const uploadImageState = useSelector(state => state.uploadChapterImage);
  const [device,setDevice] = useState('desktop');
  useEffect(() => {
    dispatch(postDynamicLink());
  },[dispatch]);

  const getMaxWidth = (deviceParam) => {
    if(deviceParam==='phone'){
      return '320px';
    }
    if(deviceParam==='tablet'){
      return '801px';
    }
    if(deviceParam==='desktop'){
      return '1200px';
    }
  }

  return (
    <Modal {...props} fullscreen>
    <Modal.Header>
      <h4>Pratinjau</h4>
      <div>
        {!isMobile && (
          <>
              <CustomButton 
              variant={device==='desktop' ? "primary" : "secondary"}
              className="mx-2"
              onClick={() => setDevice('desktop')}>
              <FontAwesomeIcon icon={faDesktop} /> 
            </CustomButton>
            <CustomButton 
              variant={device==='tablet' ? "primary" : "secondary"} 
              className="mx-2"
              onClick={() => setDevice('tablet')}>
              <FontAwesomeIcon icon={faTabletScreenButton} />
            </CustomButton>
            <CustomButton 
              variant={device==='phone' ? "primary" : "secondary"} 
              className="mx-2"
              onClick={() => setDevice('phone')}>
              <FontAwesomeIcon icon={faMobileScreen} />
            </CustomButton>
          </>
        )}
      
        <IconButton faIcon={faXmark} className="mx-2" onClick={onHide} />
      </div>
    </Modal.Header>
    <Modal.Body style={{backgroundColor:'#F6F7F9',display:'flex',alignItems:'baseline',justifyContent: 'center'}}>
      <Card style={{maxWidth: getMaxWidth(device),width: '100%',height: 'auto'}} className="my-2 p-4">
        <Card.Body >
          <h2 className="text-center">{title !== '' ? title : '-'}</h2>
          <Sizedbox height="20px" />
          <div className="info">
           <IconText imagePath="/assets/Show.png" text="-"/>
           <IconText imagePath="/assets/Document.png" text="-"/>
           <IconText imagePath="/assets/Calendar.png" text="-"/>
           <IconText imagePath="/assets/Time Circle.png" text="-"/>
          </div>
          <Sizedbox height="20px" />
          {type==='text' && <span style={{userSelect:'none',margin: '20px 0'}}>{parse(fullText)}</span> }
          {type==='image' && (
            <div className="containerImg">
              {uploadImageState.data.map((img,i) => <img alt={i} key={i} src={img.data.url}  style={{width: '100%',margin: '20px 0',userSelect: 'none'}}/>)}
            </div>
          )}
          {type==='pdf' && (
             uploadPdfState.data != null 
             ? <>
                <iframe title="writing" src={`https://docs.google.com/viewer?url=${uploadPdfState.data[0].data.url}&embedded=true`} width="100%" height="500px" ></iframe>
              </>

            : <p>PDF not found</p>
          )}
        </Card.Body>
      </Card>
    </Modal.Body>
  </Modal>
  )
}

export default PreviewChapter