import { Navigate, Outlet } from "react-router";
import { SessionKey } from "../values/constants";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postCheckLogin } from "../../features/auth/authSlice";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { decryptCookies } from "../helpers/cryptoHelper";

const ProtectedRoute = () => {
  const userId = decryptCookies(Cookies.get(SessionKey.userId));
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const tokenParams = queryParams.get("token");
  useEffect(() => {
    if (tokenParams && !userId) {
      dispatch(postCheckLogin(tokenParams));
    } else if (tokenParams) {
      window.location.replace("/");
    }
  }, []);

  if (tokenParams && !userId) {
    return <div>Redirecting...</div>;
  }

  if (userId === undefined) return <Navigate to="/login" replace />;
  else return <Outlet />;
};

export default ProtectedRoute;
