import React, { useEffect } from "react";
import { signOut, getAuth } from "firebase/auth";
import { initializeApp } from "@firebase/app";
import Cookies from "js-cookie";
import { SessionKey } from "../../utils/values/constants";
import { firebaseConfig } from "../../utils/helpers/firebaseConfig";
import { useLocation } from "react-router-dom";
const LogoutPage = () => {
  const location = useLocation();
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    signOut(auth);

    Cookies.remove(SessionKey.profileImg);
    Cookies.remove(SessionKey.profileName);
    Cookies.remove(SessionKey.token);
    Cookies.remove(SessionKey.userId);
    const kbmLink = process.env.REACT_APP_REDIRECT_KBM;
    const queryParams = new URLSearchParams(location.search);
    const newLogoutParams = queryParams.get("new_logout");
    const silverCoinParams = queryParams.get("silver_coin");
    const goldCoinParams = queryParams.get("gold_coin");
    const redirectLink = `${kbmLink}?new_logout=${newLogoutParams}&silver_coin=${silverCoinParams}&gold_coin=${goldCoinParams}`;
    window.location.replace(redirectLink);
  }, []);

  return <div>Logout...</div>;
};

export default LogoutPage;
