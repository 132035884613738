import './cardChapterStyle.css';

const CardChapter = ({
  onClick,
  index,
  id,
  title,
  children,
  isDraft = false,
  onSetTopup,
  companyType,
}) => {
  
  return (
    <div 
    className="card-chapter"
    key={id}
    >
        <div 
            onClick={onClick}
          className="leading">
            {index}
        </div>
        <div 
            onClick={onClick}
          className="title">
            {isDraft && <span style={{color: "#20BF6B"}}>Draf - </span>} 
            {title==='' ? <span style={{color: "#20BF6B" }}>Tidak ada judul</span> : title }
        </div>
        {/* {children} */}
        {children ? children : (
          <>
             <div style={{width: '12px'}} />
             <img 
              alt='edit-chapter'
              onClick={onClick}
              style={{
                cursor: 'pointer',
              }}
              src='/assets/icons/edit-chapter.svg' />
            {companyType && (
              <>
                <div style={{width: '12px'}} />
                <img 
                  alt='edit topup'
                  onClick={onSetTopup}
                  style={{
                    cursor: 'pointer',
                  }}
                  src='/assets/icons/edit-topup.svg' />
              </>
            )}
            
            <div style={{width: '18px'}} />
          </>
        )}
       
      
    </div>
  )
}


export default CardChapter