import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import myStoryService from '../../services/myStoryService';

const name = 'myStory';

export const getMyStory = createAsyncThunk(
    `${name}/getMyStory`, async (storyId) => {
     return  await myStoryService.getEditStory(storyId);
    }
)

let toastId;
const myStorySlice = createSlice({
    name: name,
    initialState:{
        isLoading: true,
        status: false,
        data: {},
    },extraReducers:{
        [getMyStory.pending](state){
            state.isLoading = true;
            state.status = false;
            state.data = {};
            
            toast.dismiss();
            toastId = toast.loading('Sedang Memuat');
        },
        [getMyStory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = payload.status;
            state.data = payload.data.story;
            toast.dismiss();
        },
        [getMyStory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            state.data = {};
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        }
    }
})

export default myStorySlice.reducer;