import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import chapterService from "../../services/chapterService";
const name = "actionChapter";

export const typeActionChapter = Object.freeze({
  setCost: "set-cost",
  upsert: "upsert",
  removeRedis: "remove-redis",
  delete: "delete",
});

export const upsertChapter = createAsyncThunk(
  `${name}/upsertChapter`,
  async ({
    title,
    storyId,
    status,
    saveDraft,
    fullText,
    type,
    media,
    chapterId = "",
    wordsCount,
  }) => {
    return await chapterService.upsertChapter({
      title: title,
      storyId: storyId,
      status: status,
      saveDraft: saveDraft,
      fullText: fullText,
      type: type,
      media: media,
      chapterId: chapterId,
      wordsCount: wordsCount,
    });
  }
);

export const postRemoveRedis = createAsyncThunk(
  `${name}/postRemoveRedis`,
  async ({ storyId }) => {
    return await chapterService.postDeleteRedis({ storyId: storyId });
  }
);

export const postDeleteChapter = createAsyncThunk(
  `${name}/deleteChapter`,
  async ({ storyId, chapterId }) => {
    return await chapterService.deleteChapter({
      chapterId: chapterId,
      storyId: storyId,
    });
  }
);

export const postSetCostChapter = createAsyncThunk(
  `${name}/deleteChapter`,
  async ({ storyId, chapterId, type, amount }) => {
    return await chapterService.postSetCostChapter({
      chapterId: chapterId,
      storyId: storyId,
      type: type,
      amount: amount,
    });
  }
);
let toastId;
const actionChapterSlice = createSlice({
  name: name,
  initialState: {
    isLoading: false,
    status: false,
    data: null,
    typeAction: null,
  },
  reducers: {
    resetActionChapter: (state, { payload }) => {
      state.isLoading = false;
      state.status = false;
      state.data = null;
      state.typeAction = null;
    },
  },
  extraReducers: {
    [upsertChapter.pending](state) {
      state.isLoading = true;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.upsert;
    },
    [upsertChapter.fulfilled](state, { payload }) {
      state.isLoading = true;
      state.status = true;
      state.data = payload.data.chapter;
      state.typeAction = typeActionChapter.upsert;
      toast.success(payload.message, {
        theme: "colored",
      });
    },
    [upsertChapter.rejected](state, { error }) {
      state.isLoading = false;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.upsert;
      toast.error(error.message, {
        theme: "colored",
      });
    },
    [postRemoveRedis.pending](state) {
      state.isLoading = true;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.removeRedis;
    },
    [postRemoveRedis.fulfilled](state, { payload }) {
      state.isLoading = true;
      state.status = true;
      state.data = null;
      state.typeAction = typeActionChapter.removeRedis;
      toast.success(payload.message, {
        theme: "colored",
      });
    },
    [postRemoveRedis.rejected](state, { error }) {
      state.isLoading = false;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.removeRedis;
      toast.error(error.message, {
        theme: "colored",
      });
    },
    [postDeleteChapter.pending](state) {
      state.isLoading = true;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.delete;
      toastId = toast.loading("Sedang Proses");
    },
    [postDeleteChapter.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = true;
      state.data = null;
      state.typeAction = typeActionChapter.delete;
      toast.update(toastId, {
        render: payload.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    },
    [postDeleteChapter.rejected](state, { error }) {
      state.isLoading = false;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.delete;
      toast.update(toastId, {
        render: error.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    },
    [postSetCostChapter.pending](state) {
      state.isLoading = true;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.setCost;
    },
    [postSetCostChapter.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.status = true;
      state.data = null;
      state.typeAction = typeActionChapter.setCost;
    },
    [postSetCostChapter.rejected](state, { error }) {
      state.isLoading = false;
      state.status = false;
      state.data = null;
      state.typeAction = typeActionChapter.setCost;
      toast.error(error.message, {
        theme: "colored",
      });
    },
  },
});

export const { resetActionChapter } = actionChapterSlice.actions;

export default actionChapterSlice.reducer;
