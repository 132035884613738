import { toast } from 'react-toastify';
import React from 'react'
import { Form } from 'react-bootstrap'
import './style.css';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomTag = ({label,onEnter,disabled = false,value,errors,placeholder,touched,onRemove}) => {

    const keyPress = (e) => {
        if(e.keyCode===13){
            if(!value.includes(e.target.value)){
                onEnter(e.target.value);
                e.target.value = "";
            }else{
                toast.error(`${e.target.value} telah ada cari kata lain`,{
                    theme: 'colored',
                });
            }
          
        }
    }

  return (
    <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control disabled={disabled} onKeyDown={keyPress} placeholder={placeholder}/>
        {errors && touched && (
            <Form.Control.Feedback type="invalid">
            {errors}
          </Form.Control.Feedback>
        )}
        {value.map((tag,i) => (
        <div key={i}
        style={{
            backgroundColor: "#E6E8EB",
            margin: "10px 5px",
            padding:"10px",
            display:'inline-block',
            borderRadius: "20px",
            color: "#929AA7",
            fontSize: "12px",
            fontWeight: 500,
            }}>
            &nbsp;{tag} &nbsp;
            <div onClick={() => onRemove(tag)}
            style={{
                display:'inline-block',
                verticalAlign: 'middle',
                textAlign:'center', 
                width:"15px",
                height:"15px",
                backgroundColor: "#929AA7",
                color:'white',
                fontSize:"10px",
                borderRadius: "50%"}}>
                    <FontAwesomeIcon icon={faXmark} /> 
                </div>
        </div>))}
    </Form.Group>
  )
}

export default CustomTag