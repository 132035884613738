import React from 'react'
import Select from 'react-select';
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import './style.css';

const CustomSelect = ({label,options,...props}) => {
    const [field,meta,helpers] = useField(props);
    const { setValue } = helpers;
    const setFieldProps = (selectedOption) => {
        setValue(selectedOption.value) 
    }
    const customStyles = {
      placeholder: (provided,state) => ({
        ...provided,
        color: '#C6CBD4',
        fontSize: "14px",
        fontWeight: 400,
      }),
      control: (provided,state) => ({
        ...provided,
        borderRadius: '8px',
        padding: "0 12px",
        border: meta.touched && meta.error && '1px solid red',
      }),
      input: (provided,state) => ({
        ...provided,
        padding: '10px 0'
        
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#666E7D',
        fontSize: "16px",
        fontWeight: 500,
        
      }),
      indicatorSeparator: (provided,state) => ({
        
      })
    }
  return (
    <Form.Group className="mb-3">
    <Form.Label>{label}</Form.Label>
    <Select  {...props} {...field}
    value={options ? options.find(option => option.value===field.value ) : ""}
    onChange={selectedOption => setFieldProps(selectedOption)}
    options={options}
    
    styles={customStyles}
      />
    {meta.touched && meta.error ? (
          <Form.Text className="text-danger">
            {meta.error}
          </Form.Text>
        ) : null} 
    </Form.Group>
  )
}

export default CustomSelect