import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import termsService from "../../services/termsService";

const name = 'terms';

export const getStatusTerms = createAsyncThunk(
    `${name}/getStatusTerms`, async () => {
        return await termsService.getStatusTerms();
    }
) 

export const postAgreeTerms = createAsyncThunk(
    `${name}/postAgreeTerms`, async () => {
        return await termsService.postAgreeTerms();
    }
)


const termsSlice = createSlice({
    name:name,
    initialState: {
        isLoading: false,
        status: false,
        data: null,
    },
    extraReducers:{
        [getStatusTerms.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [getStatusTerms.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data = payload.data;
        },
        [getStatusTerms.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
        },
        [postAgreeTerms.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [postAgreeTerms.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data = null;
        },
        [postAgreeTerms.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message,{theme: 'colored'})
        }
    }
});

export default termsSlice.reducer;