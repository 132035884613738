
import { unwrapResult } from '@reduxjs/toolkit';
import {useEffect, useState} from 'react'
import { ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {ReactSortable} from 'react-sortablejs';
import { changeSeqChapter } from '../../../features/myStory/actionMyStorySlice';
import CardChapter from '../../../utils/components/cardChapter';
import CustomButton from '../../../utils/components/customButton';
import Sizedbox from '../../../utils/components/sizedbox';
import {isMobile} from 'react-device-detect';
import ModalConfirm from '../../../utils/components/modalConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBars } from '@fortawesome/free-solid-svg-icons';

const chapterModel = (chap, index,isSort = false) => {
  return ({
      'id': isSort ? chap.id : chap._id,
      'title': chap.title,
      'index': index ? index : chap.index,
      'type': chap.type,
      'status': chap.status,
      'save_draft': chap.save_draft,
      'title_draft': chap.title_draft,
      'unlock': chap.unlock,
  });
}

const ListChapterMyStory = ({
  companyType,
  chapters,
  setConfirmTopupModal,
  setSelectedChapterId,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {storyId} = useParams();
    const [isSortable, setSortable] = useState(false);
    const [isReverse, setReverse] = useState(false);
    const [titleChapter,setTitleChapter] = useState('');
    const [chapterId,setChapterId] = useState('');
    const [chapterType,setChapterType] = useState('');
    const [isEditModal,setEditModal] = useState(false);
    const [chapter,setChapter] = useState(
        chapters?.map(chap => chapterModel(chap)),
    );
    const [sortChapter,setSortChapter] = useState([]);

    // selector
    const changeSeq = useSelector((state) => state.actionMyStory);

 
    
    useEffect(() => {
        setChapter(chapters?.map(chap => chapterModel(chap)));
    },[chapters]);

    const onSortable = () => {
      if(isReverse){
        setReverse(false);
        setSortChapter(chapter.reverse());  
      }else{
        setSortChapter(chapter);
      }
      setSortable(true);
    }


    const onSaveSort= () => {
      const paramObject = {};
      sortChapter.map((sort,i) => paramObject[sort.id] = i + 1 );
      const chapt = sortChapter.map((chap,i) => chapterModel(chap,i + 1,true));
      dispatch(changeSeqChapter(paramObject))
        .then(unwrapResult)
        .then((_) => {
          setChapter(chapt);
          setSortable(false);
        });
      
    }

    const onReverse = () => {
      setReverse(!isReverse);
        setChapter(chapter.reverse());
      
    }
  if(chapters.length===0)
    return (
      <div style={{display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}}>
        <img alt='empty-data' src="/assets/Empty_Data.png" width={isMobile ? "50%" : "25%"}/>
        <h5 style={{color: "#20BF6B"}}>Bab Kosong</h5>
      </div>
    )
  else if(isSortable)
    return (
        <>
        {isMobile &&  <h4 style={{marginBottom:'20px',textAlign:'center'}}>Bab ({chapter.length})</h4>}
      <div style={{display: 'flex',justifyContent: 'space-between'}}>
       
        {isMobile && (
          <>
            <CustomButton variant='secondary' onClick={() => setSortable(false)}>Batal</CustomButton>
              <div style={{width:'10px'}}></div>
              <CustomButton isLoading={changeSeq.isLoading} onClick={onSaveSort}>Simpan</CustomButton>
          </>
        )}

        {!isMobile && (
          <>
             <h4 style={{margin:'auto 0'}}>Bab ({chapter.length})</h4>
            <ButtonGroup >
            <CustomButton variant="secondary" onClick={() => setSortable(false)}>Batal</CustomButton>
              <div style={{width:'10px'}}></div>
              <CustomButton isLoading={changeSeq.isLoading} onClick={onSaveSort}>Simpan</CustomButton>
            </ButtonGroup>
            </>
        )}
      </div>
      <Sizedbox height="20px" />
      <ReactSortable list={sortChapter} setList={setSortChapter}>
        {sortChapter.map((item,i) => (
          
          <CardChapter 
            key={i} 
            index={item.index} 
            id={item.id} 
            title={item.title} 
            isDraft={item.status==='draft'}>
             <CustomButton 
              styles={{
                fontSize:'18px',
                width: '70px', 
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }} 
              variant="secondary">
                <FontAwesomeIcon icon={faBars} />
              </CustomButton>
          </CardChapter>
        ))}
      </ReactSortable>
      </>
    )
  else 
    return (
      <>
      <ModalConfirm
      description={`Lanjut edit bab ${titleChapter}\n Jika kamu ingin melanjutkan perubahan bab, silahkan klik Ya`}
      show={isEditModal}
      onYesClick={() => {
        navigate(`/edit-chapter/${storyId}/${chapterId}?type=${chapterType}&status=draft`,{replace: true});
      }}
      onNoClick={() => {
        navigate(`/edit-chapter/${storyId}/${chapterId}?type=${chapterType}&status=active`,{replace: true});
      }}
      onHide={() => setEditModal(false)}
      title="Lanjut Edit"
       />
      {isMobile &&  <h4 style={{marginBottom:'20px',textAlign:'center'}}>Bab ({chapter.length})</h4>}
      <div style={{display: 'flex',justifyContent: 'space-between'}}>
       
        {isMobile && (
          <>
            <CustomButton  onClick={onSortable} variant="secondary">Urutkan</CustomButton>
              <CustomButton  onClick={onReverse}>
                {isReverse ? 'Z - A' : 'A - Z'} &nbsp;
                {isReverse ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
              </CustomButton>
          </>
        )}

        {!isMobile && (
          <>
             <h4 style={{margin:'auto 0'}}>Bab ({chapter.length})</h4>
            <ButtonGroup >
            <CustomButton variant="secondary" onClick={onSortable}>Urutkan</CustomButton>
            <Sizedbox width="10px" />
              <CustomButton  onClick={onReverse}>
                {isReverse ? 'Z - A' : 'A - Z'} &nbsp;
                {isReverse ? <FontAwesomeIcon icon={faArrowUp} />  : <FontAwesomeIcon icon={faArrowDown}/>}
              </CustomButton>
            </ButtonGroup>
            </>
        )}
      </div>
      <Sizedbox height="20px" />
      {chapter.map((item,i) => (
        <CardChapter 
          companyType={companyType}
          key={i} 
          index={item.index} 
          id={item.id} 
          title={item.status==='draft' && item.save_draft==='true' && item.type==='text' ? item.title_draft : item.title} 
          isDraft={item.status==='draft'} 
          onSetTopup={() => {
            setSelectedChapterId(item.id);
            setConfirmTopupModal(true);
          }}
          onClick={() => {
            if(item.save_draft==='true' && item.status==='active'){
              setTitleChapter(item.title);
              setChapterId(item.id);
              setChapterType(item.type);
              setEditModal(true);
            }else{
              navigate(`/edit-chapter/${storyId}/${item.id}?type=${item.type}`,{replace: true});
            }
          }} />
      ))}
      </>
    )
}

export default ListChapterMyStory