import React from 'react'
import CustomButton from './customButton';
import {Modal} from 'react-bootstrap';

const ModalConfirm = ({title,description,yesText = 'Ya',onYesClick,isLoading,onNoClick,noText = 'Tidak',...props}) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton variant="secondary" onClick={onNoClick ?? props.onHide}>{noText}</CustomButton>
        <CustomButton variant="primary" isLoading={isLoading} onClick={onYesClick}>{yesText}</CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm