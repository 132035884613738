export const SessionKey = Object.freeze({
    'userId': 'userId',
    'token': 'token',
    'profileImg': 'profileImg',
    'profileName': 'profileName',
});

export const ErrorResponse = Object.freeze({
    'status': false,
    'message': 'Terjadi kesalahan mohon coba beberapa saat lagi',
});

//export const BaseUrl = 'https://dev-service.iamhoster.com';
export const BaseUrl = 'https://beta-api-gateway.kbmapp.com';

export const clientId = '935968497295-53cd6ebstv7o74p6issncguj5updjooe.apps.googleusercontent.com';