import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatusTerms,
  postAgreeTerms,
} from "../../features/terms/termsSlice";
import CustomButton from "../../utils/components/customButton";
import { SessionKey } from "../../utils/values/constants";
import { unwrapResult } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { decryptCookies } from "../../utils/helpers/cryptoHelper";

const TermsWriting = () => {
  const dispatch = useDispatch();
  const termsState = useSelector((state) => state.terms);
  const token = decryptCookies(Cookies.get(SessionKey.token));
  const [show, setShow] = useState(token === undefined ? false : true);
  useEffect(() => {
    if (termsState.data === null && token) {
      dispatch(getStatusTerms());
    }
  }, [dispatch, termsState.data]);

  useEffect(() => {
    setShow(termsState?.data?.aggreemant_status?.status === false);
  }, [termsState]);

  const onAgree = () => {
    dispatch(postAgreeTerms())
      .then(unwrapResult)
      .then((_) => {
        setShow(false);
      });
  };
  return (
    <Modal show={show} fullscreen>
      <Modal.Header>
        <Modal.Title>Syarat & Ketentuan Menulis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          title="writing"
          src="https://kbm.id/pages/writing.html"
          allowFullScreen={true}
          width="100%"
          height="100%"
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton isLoading={termsState.isLoading} onClick={onAgree}>
          Setuju
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsWriting;
