import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import myStoryService from "../../services/myStoryService";
import { toast } from "react-toastify";
const name = "actionStory";

export const upsertStory = createAsyncThunk(
    `${name}/upsert`,async ({
        storyId = '',
        title,
        category,
        tags,
        synopsis,
        coverImg,
        isShowWordCount,
    }) => {
        return await myStoryService.upsertStory({
            storyId: storyId,
            title:title,
            category:category,
            tags: tags,
            synopsis:synopsis,
            coverImg:coverImg,
            isShowWordCount: isShowWordCount,
        });
    }
)

export const changeSeqChapter = createAsyncThunk(
    `${name}/changeSeqChapter`, async (chapters) => {
        return await myStoryService.changeSeqChapter(chapters);
    }
)

export const monetizeStory = createAsyncThunk(
    `${name}/monetizeStory`, async (storyId) => {
       return await myStoryService.monetizeStory(storyId);
    }
)

export const completeStory = createAsyncThunk(
    `${name}/completeStory`, async (storyId) => {
        return await myStoryService.completeStory(storyId);
    }
)

export const unCompleteStory = createAsyncThunk(
    `${name}/unCompleteStory`, async (storyId) => {
        return await myStoryService.unCompleteStory(storyId);
    }
)

export const deleteStory = createAsyncThunk(
    `${name}/deleteStory`, async(storyId) =>{
        return await myStoryService.deleteStory(storyId);
    }
)


let toastId;
const actionMyStory = createSlice({
    name: name,
    initialState: {
        isLoading: false,
        status: false,
        data: null,
    },
    extraReducers:{
        [upsertStory.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [upsertStory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            state.data = payload.data.story;
            toast.success(payload.message,{
                theme: 'colored',
            });
        },
        [upsertStory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message,{
                theme: 'colored',
            });
        },
        [changeSeqChapter.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [changeSeqChapter.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            toast.success(payload.message,{
                theme: 'colored',
            });
        },
        [changeSeqChapter.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message,{
                theme: 'colored',
            });
        },
        [monetizeStory.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [monetizeStory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            toast.success(payload.message,{
                theme: 'colored',
            });
        },
        [monetizeStory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message,{
                theme: 'colored',
            });
        },
        [completeStory.pending](state){
            state.isLoading = true;
            state.status = false;
            toastId = toast.loading('Sedang Proses');
        },
        [completeStory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            toast.update(toastId,{ render: payload.message, type: "success", isLoading: false, autoClose: 1000, });
        },
        [completeStory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        },
        [unCompleteStory.pending](state){
            state.isLoading = true;
            state.status = false;
            toastId = toast.loading('Sedang Proses');
        },
        [unCompleteStory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            toast.update(toastId,{ render: payload.message, type: "success", isLoading: false, autoClose: 1000, });
        },
        [unCompleteStory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        },
        [deleteStory.pending](state){
            state.isLoading = true;
            state.status = false;
        },
        [deleteStory.fulfilled](state,{payload}){
            state.isLoading = false;
            state.status = true;
            toast.success(payload.message);
        },
        [deleteStory.rejected](state,{error}){
            state.isLoading = false;
            state.status = false;
            toast.error(error.message)
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        },
    }
});

export default actionMyStory.reducer;