import React from 'react'
import {isMobile} from 'react-device-detect';
const MyStoryEmpty = () => {
  return (
        <div style={{display:'flex',flexFlow:'column',justifyContent:'center',alignItems:'center'}}>
            <img alt='empty' src="/assets/Empty_Data.png" width={isMobile ? "50%" : "25%"}/>
            <h5 style={{color: "#20BF6B"}}>Cerita Kosong</h5>
        </div>
  )
}

export default MyStoryEmpty;