import React from 'react'

const CoverBook = ({
  
  src,
  width = '137px',
  height = '196px',
  borderRadius= '5px',
  margin = '0px',
  onClick,
  style,
  ...props
  }) => {
  return (
    <img 
    onClick={onClick}
    src={src} 
    width={width} 
    height={height} 
    alt="cover"
    style={{
      borderRadius: borderRadius,
      margin: margin,objectFit: 'cover',
      ...style,
    }}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src="/assets/COVER.jpg";
    }}
    {...props} />
  )
}

export default CoverBook