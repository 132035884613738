import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import chapterService from "../../services/chapterService";

const name = 'my-list-chapter';
export const getMyListChapter = createAsyncThunk(
    `${name}/getMyListChapter`,async ({storyId,pageNumber}) =>{
    return await chapterService.getMyListChapter({
        storyId: storyId,
        pageNumber: pageNumber,
    })
});
let toastId;
const myListChapterSlice = createSlice({
    name: name,
    initialState: {
        isLoading :false,
        status : false,
        data: [],
        isLoadMore: false,
        isError: false,
    },
    reducers: {
        resetMyListChapter: (state) => {
            state.isLoading = false;
            state.status = false;
            state.data = [];
            state.isLoadMore = false;
            state.isError = false;
        }
    },
    extraReducers: {
        [getMyListChapter.pending](state){
            state.isLoading = true;
            state.status = false;
            state.isLoadMore = false;
            state.isError = false;
            toastId = toast.loading('Memuat data');
        },
        [getMyListChapter.fulfilled](state,{payload}){
            state.isLoading = true;
            state.status = false;
            state.data.push(...payload.data.chapters);
            state.isLoadMore = payload.is_loadmore;
            state.isError = false;
            toast.dismiss();
        },
        [getMyListChapter.rejected](state,{error}){
            state.isLoading = true;
            state.status = false;
            state.isLoadMore = false;
            state.isError = false;
            toast.update(toastId,{ render: error.message, type: "error", isLoading: false ,autoClose: 1000, });
        },
    }
});

export const {resetMyListChapter} = myListChapterSlice.actions;

export default myListChapterSlice.reducer;